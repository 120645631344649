/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 3/4/2021
 * @Example
 */
import React, {useState, useEffect ,useRef}                    from "react";
import { connect }                                             from "react-redux";
import PropTypes                                               from "prop-types";
import * as W09F2200Actions                                    from "../../../../redux/W0X/W09F2200/W09F2200_actions";
import * as generalActions                                     from "../../../../redux/general/general_actions";
import { bindActionCreators, compose }                         from "redux";
import Config                                                  from "../../../../config";
import {FormLabel as Label, IconButton, TextField, makeStyles} from "@material-ui/core";
import Modal                                                   from "../../../common/modal/modal";
import {Col, FormGroup, Row}                                   from "react-bootstrap";
import {Combo}                                                 from "../../../common/form-material";
import Attachments                                             from "../../../common/attachments/attachments";
import AttachFileIcon from "@material-ui/core/SvgIcon/SvgIcon";
import ButtonGeneral from "../../../common/button/button-general";
import W84F3005      from "../../../W8X/W84/W84F3005/W84F3005";
import Icons         from "../../../common/icons/";
import GridActionBar from "../../../grid-container/grid-actionbar";
import GridContainer from "../../../grid-container/grid-container";
import { Column }    from "devextreme-react/data-grid";
import HistoryIcon   from "@material-ui/icons/History"
import _             from "lodash";
import DateBoxPicker from "../../../common/form-material/date-box";
import W09F2205      from "../W09F2205/W09F2205";
import moment        from "moment";


const useStyles = makeStyles(() => ({
    styleLabel: {
        fontStyle: 14,
        fontWeight: 700,
        color: "#111D5E"
    }
}));
const W09F2200 = (props) => {

    const _initDataForm = {
        PropertyName: "",
        OldPropertyValue: "",
        Notes: "",
        PropertyValue: "",
        AddressName: "",
        LabelProvince: "",
        ProvinceID: "",
        LabelDistrict: "",
        DistrictID: "",
        LabelWard: "",
        WardID: "",
        TransID: "",
        PropertyID: "",
        EmployeeID: "",
        LabelProvinceName: "",
        ProvinceName: "",
        LabelDistrictName: "",
        DistrictName: "",
        LabelWardName: "",
        WardName: "",
    };

    const _w84F3005PopupData = {
        TransID: null
    };

    const initFilter = {
        skip: 0,
        limit: 10
    };

    const initDataGrid = {
        rows: [],
        total: 0
    };

    const _initRowData = {
        row: {},
        mode: ""
    };

    const { rowData, mode, open, onClose} = props || {};
    const [dataForm, setDataForm] = useState(_initDataForm);
    const [_rowData, setRowData] = useState(_initRowData);
    const [dataCboRelative, setDataCboRelative] = useState([]);
    const [dataOldAttachments, setDataOldAttachments] = useState([]);
    const [dataCboProvinceLabel, setDataCboProvinceLabel] = useState([]);
    const [dataCboProvince, setDataCboProvince] = useState([]);
    const [dataCboDistrict, setDataCboDistrict] = useState([]);
    const [dataCboLabelDistrict, setDataCboLabelDistrict] = useState([]);
    const [dataCboWardLabel, setDataCboWardLabel] = useState([]);
    const [dataGrid, setDataGrid] = useState(initDataGrid);
    const [dataCboWard, setDataCboWard] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [loading, _setLoading] = useState({loadingForm: false, loadingUpdate: false});
    const [isDisabled, setIsDisabled] = useState(false);
    const [showW84F3005Popup, setShowW84F3005Popup] = useState(false);
    const [showW09F2205Popup, setShowW09F2205Popup] = useState(false);
    const [isTypeTextBox, setIsTypeTextBox] = useState(false);
    const [isTypeString, setIsTypeString] = useState(false);
    const [isTypeDatePicker, setIsTypeDatePicker] = useState(false);
    const [isTypeCombo, setIsTypeCombo] = useState(false);
    const attRef = useRef(null);
    const attachments = useRef([]);
    const deletedFile = useRef([]);
    const w84F3005PopupData = useRef(_w84F3005PopupData);
    const classes = useStyles();

    const loadForm = () => {
        const params = {
            TransID: "",
            Language: Config.language || 84,
            FormID: "W09F2210",
            EmployeeID: _.get(rowData, "employeeID", ""),
            PropertyID: _.get(rowData, "propertyID", ""),
            skip: initFilter.skip,
            limit: initFilter.limit,
        };
        setLoading({loadingForm: true});
        props.w09f2200Actions.getForm(params, (error, data) => {
            setLoading({loadingForm: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data) {
                setDataGrid({
                    total: data.total,
                    rows: data.rows
                });
            }
        });
    };

    const loadCboAddress = (sourceName = "", parentID = "") => {
        const params = {
            Language: Config.language || 84,
            CodeTable: 1,
            SourceName: sourceName,
            ParentID: parentID,
        };
        setLoading({loadingForm: true});
        props.w09f2200Actions.getCboAddress(params, (error, data) => {
            setLoading({loadingForm: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data) {
                _setDataCbo(sourceName,data);
            }
        });
    };

    const _setDataCbo = (sourceName,data) => {
        switch (sourceName) {
            case "ProvinceLabel":
                setDataCboProvinceLabel(data);
                break;
            case "TINH/THANH":
                setDataCboProvince(data);
                break;
            case "DistrictLabel":
                setDataCboLabelDistrict(data);
                break;
            case "WardLabel":
                setDataCboWardLabel(data);
                break;
            case "QUAN/HUYEN":
                setDataCboDistrict(data);
                break;
            case "XA/PHUONG":
                setDataCboWard(data);
                break;
            default:
                break;
        }
    };

    const loadListRelative = (fieldName = "") => {
        const params = {
            Language: Config.language || 84,
            FieldName: fieldName
        };
        setLoading({loadingListRelative: true});
        props.w09f2200Actions.getListRelative(params, (error, data) => {
            setLoading({loadingListRelative: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if(data) {
                setDataCboRelative(data);
            }
        });
    };

    const checkTypePropertyValue = (key) => {
        if(!key) return false;
        switch (key) {
            case "NUMIDCARD":
            case "IDCARD":
            case "PASSPORT":
            case "TEL":
            case "MOBILE":
            case "CompanyTEL":
            case "EMAIL":
            case "EMCONNAME1":
            case "EMCONPHONE1":
            case "EMCONNAME2":
            case "EMCONPHONE2":
                setIsTypeTextBox(true);
                break;
            case "CONADDR":
            case "PERADDR":
            case "PROADDR":
            case "EMCONADD1":
            case "EMCONADD2":
                loadCboAddress("ProvinceLabel", "");
                loadCboAddress("TINH/THANH", "");
                loadCboAddress("DistrictLabel", "");
                loadCboAddress("WardLabel", "");
                setIsTypeString(true);
                break;
            case "NUMIDDATE":
            case "IDCARDDATE":
            case "PASSPORTBEGIN":
            case "PASSPORTEND":
                setIsTypeDatePicker(true);
                break;
            case "ETHNIC":
            case "RELIGION":
            case "COUNTRY":
            case "NUMIDPLACE":
            case "IDCARDPLACE":
            case "PASSPORTPLACE":
            case "EDUCATION":
            case "PROFESSIONAL":
            case "MAJOR":
            case "POLITICS":
            case "EMCONREL1":
            case "EMCONREL2":
            case "NATIVEPLACE":
            case "MARITALSTATUS":
                loadListRelative(_.get(rowData, "fieldName", ""));
                setIsTypeCombo(true);
                break;
            default:
                break;

        }
    };

    useEffect(() => {
        if(mode === "edit") {
            setData({OldPropertyValue: _.get(rowData, "oldPropertyID", "")});
            checkTypePropertyValue(_.get(rowData, "propertyID", ""));
            loadAttachments();
            loadForm();
            if(Number(rowData.AppStatusID) !== 0) {
                setIsDisabled(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(dataCboProvinceLabel && dataCboProvinceLabel.length > 0) {
            _setName("LabelProvince",_.get(dataCboProvinceLabel[0], "ID", ""));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataCboProvinceLabel]);

    useEffect(() => {
        if(dataCboProvince && dataCboProvince.length > 0) {
            _setName("ProvinceID",_.get(dataCboProvince[0], "Code", ""));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataCboProvince]);

    useEffect(() => {
        if(dataCboDistrict && dataCboDistrict.length > 0) {
            _setName("DistrictID",_.get(dataCboDistrict[0], "Code", ""));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataCboDistrict]);

    useEffect(() => {
        if(dataCboLabelDistrict && dataCboLabelDistrict.length > 0) {
            _setName("LabelDistrict",_.get(dataCboLabelDistrict[0], "ID", ""));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataCboLabelDistrict]);

    useEffect(() => {
        if(dataCboWardLabel && dataCboWardLabel.length > 0) {
            _setName("LabelWard",_.get(dataCboWardLabel[0], "ID", ""));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataCboWardLabel]);

    useEffect(() => {
        if(dataCboWard && dataCboWard.length > 0) {
            _setName("WardID",_.get(dataCboWard[0], "Code", ""));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataCboWard]);


    useEffect(() => {
        if(!!_.get(dataForm, "ProvinceID", "")) {
            loadCboAddress("QUAN/HUYEN",_.get(dataForm, "ProvinceID", ""));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm?.ProvinceID]);

    useEffect(() => {
        if(!!_.get(dataForm, "DistrictID", "")) {
            loadCboAddress("XA/PHUONG",_.get(dataForm, "DistrictID", ""));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm?.DistrictID]);

    const setLoading = (obj) => {
        _setLoading({...loading,...obj});
    };

    const setData = (obj) => {
        setDataForm({...dataForm,...obj});
    };

    const loadAttachments = () => {
        const param = {
            KeyID:  _.get(rowData, "propertyID", ""),
            TableName: "D75T1010"
        };
        setLoading({loadingAttachment: true});
        props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            setLoading({loadingAttachment: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data && data.length > 0) {
                setDataOldAttachments(data);
            }
        });
    };


    const onCloseModal = (formID, isLoaded = false) => {
        if(formID === "W09F2205") {
            setShowW09F2205Popup(false);
            isLoaded && loadForm();
        }
        if(formID === "W09F2200") {
            if(onClose) onClose();
        }

    };

    const onAttachment = () => {
        if (attRef) {
            attRef.onAttachment();
        }
    };

    const onUploading = (value) => {
        setUploading(value);
    };

    const onChangeAttachments = (e) => {
        attachments.current = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            deletedFile.current = [ ...e.deletedFiles];
            const _arrRemove = deletedFile.current.map((d) => d.AttachmentID);
            setDataOldAttachments(dataOldAttachments.filter((att) => {
                return _arrRemove.indexOf(att.AttachmentID) < 0;
            }));
        }
    };

    const _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(attachments.current);
        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    const handleChange = (key,e) => {
        if(!key) return false;
        let value = _.get(e, "value", _.get(e, "target.value", ""));
        switch (key) {
            case "PropertyValue":
                _setName(key,value);
                break;
            case "AddressName":
                _setName(key,value);
                break;
            case "LabelProvince":
                _setName(key,value);
                break;
            case "ProvinceID":
                _setName(key,value);
                break;
            case "LabelDistrict":
                _setName(key,value);
                break;
            case "DistrictID":
                _setName(key,value);
                break;
            case "LabelWard":
                _setName(key,value);
                break;
            case "WardID":
                _setName(key,value);
                break;
            case "Notes":
                _setName(key,value);
                break;
            default:
                break;
        }
    };

    const _setName = (key,value) => {
        const { AddressName, LabelProvinceName,DistrictName, ProvinceName, LabelDistrictName, LabelWardName, WardName} = dataForm;
        let dataFilter = "";
        let name = "";
        switch (key) {
            case "PropertyValue":
                setData({PropertyValue: value});
                break;
            case "AddressName":
                name = value + " " + LabelWardName + " " + WardName + " " + LabelDistrictName + " " +
                    DistrictName + " " + LabelProvinceName + " " + ProvinceName;
                setData({AddressName: value, PropertyValue: name});
                break;
            case "LabelProvince":
                dataFilter = dataCboProvinceLabel.find(item => item.ID === value);
                name = AddressName + " " + LabelWardName + " " + WardName + " " + LabelDistrictName +
                    " " + DistrictName + " " + (dataFilter?.Name || "") + " " + ProvinceName;
                setData({LabelProvince: value, LabelProvinceName: (dataFilter?.Name || ""), PropertyValue: name});
                break;
            case "ProvinceID":
                dataFilter = dataCboProvince.find(item => item.Code === value);
                name = AddressName + " " + LabelWardName + " " + WardName + " " + LabelDistrictName + " " +
                    DistrictName + " " + LabelProvinceName + " " + (dataFilter?.Name || "");
                setData({ProvinceID: value,ProvinceName: (dataFilter?.Name || ""), PropertyValue: name});
                break;
            case "LabelDistrict":
                dataFilter = dataCboLabelDistrict.find(item => item.ID === value);
                name = AddressName + " " + LabelWardName + " " + WardName + " " + (dataFilter?.Name || "") + " " +
                    DistrictName + " " + LabelProvinceName + " " + ProvinceName;
                setData({LabelDistrict: value, LabelDistrictName: (dataFilter?.Name || ""), PropertyValue: name});
                break;
            case "DistrictID":
                dataFilter = dataCboDistrict.find(item => item.Code === value);
                name = AddressName + " " + LabelWardName + " " + WardName + " " + LabelDistrictName + " " +
                    (dataFilter?.Name || "") + " " + LabelProvinceName + " " + ProvinceName;
                setData({DistrictID: value, DistrictName: (dataFilter?.Name || ""), PropertyValue: name});
                break;
            case "LabelWard":
                dataFilter = dataCboWardLabel.find(item => item.ID === value);
                name =  AddressName + " " + (dataFilter?.Name || "") + " " + WardName + " " + LabelDistrictName + " " +
                    DistrictName + " " + LabelProvinceName + " " + ProvinceName;
                setData({LabelWard: value, LabelWardName: (dataFilter?.Name || ""), PropertyValue: name});
                break;
            case "WardID":
                dataFilter = dataCboWard.find(item => item.Code === value);
                name = AddressName + " " + LabelWardName + " " + (dataFilter?.Name || "") + " " +
                    LabelDistrictName + " " + DistrictName + " " + LabelProvinceName + " " + ProvinceName;
                setData({WardID: value, WardName: (dataFilter?.Name || ""), PropertyValue: name});
                break;
            case "Notes":
                setData({Notes: value});
                break;
            default:
                break;
        }
    };

    const onSave = () => {
        const arrAttachments = _getAttachments();
        const params = {
            PropertyID: _.get(rowData, "propertyID", ""),
            PropertyName: _.get(dataForm, "PropertyName", ""),
            PropertyValue: _.get(dataForm, "PropertyValue", ""),
            EmployeeID: _.get(rowData, "employeeID", ""),
            OldPropertyValue: dataForm?.OldPropertyValue ?
                (isTypeDatePicker ? moment(dataForm?.OldPropertyValue, "DD/MM/YYYY").format("YYYY-MM-DD") : dataForm.OldPropertyValue)  : null,
            Notes: _.get(dataForm, "Notes", ""),
            AddressName: _.get(dataForm, "AddressName", ""),
            LabelProvince: _.get(dataForm, "LabelProvince", ""),
            ProvinceID: _.get(dataForm, "ProvinceID", ""),
            LabelDistrict: _.get(dataForm, "LabelDistrict", ""),
            DistrictID: _.get(dataForm, "DistrictID", ""),
            LabelWard: _.get(dataForm, "LabelWard", ""),
            WardID: _.get(dataForm, "WardID", ""),
            arrAttachment: JSON.stringify(arrAttachments)
        };
        setLoading({loadingUpdate: true});
        props.w09f2200Actions.save(params, (error, data) => {
            setLoading({loadingUpdate: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data) {
                if(data.Status === 1) {
                    let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }
                if(data.Status === 0) {
                    Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                    onClose && onClose();
                }

            }
        });
    };

    const onHistoryApproval = (e, data = {}) => {
        w84F3005PopupData.current.TransID = data.TransID;
        setShowW84F3005Popup(true);
    };

    const onEdit = (e) => {
        const {data} = e.row;
        setRowData({row: {
                TransID: _.get(data, "TransID", ""),
                propertyID: _.get(data, "PropertyID", ""),
                fieldName: _.get(rowData, "fieldName", ""),
                AppStatusID:  _.get(data, "AppStatusID", "")
            }, mode: "edit"});
        setShowW09F2205Popup(true);
    };

    const onDelete = (data) => {
        const params = {
            TransID: _.get(data, "TransID", "")
        };
        props.w09f2200Actions.deleteData(params, (error, data) => {
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data) {
                if(data.Status === 1) {
                    let message = data.message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }
                if(data.Status === 0) {
                    Config.notify.show('success', Config.lang("Xoa_thanh_cong"), 2000);
                }

            }
        });
    };

    const cellRenderAction = (e) => {
        if(!e) return null;
        const {data} = e.row;
        return (
            <GridActionBar>
                <div className="">
                    <IconButton aria-label="view" className={"mgr10"} size="small"
                                onClick={() => onHistoryApproval(e, data)}>
                        <HistoryIcon/>
                    </IconButton>
                    <IconButton
                        aria-label="view"
                        style={{ padding: 0, width: 30, height: 30, marginRight: 4 }}
                        onClick={() => onEdit(e)}
                    >
                        <Icons name={"edit"}/>
                    </IconButton>
                    <IconButton
                        aria-label="delete"
                        disabled={data && Number(data.AppStatusID) !== 0}
                        style={{ padding: 0, width: 30, height: 30, marginRight: 3 }}
                        onClick={() => onDelete(data)}
                    >
                        <Icons name={"delete"}/>
                    </IconButton>
                </div>
            </GridActionBar>
        );
    };

    return (
        <React.Fragment>
            <W84F3005 open={showW84F3005Popup} onClose={() => setShowW84F3005Popup(false)}
                      FormID="W09F2225" TransID={w84F3005PopupData.current.TransID}/>
            {showW09F2205Popup && <W09F2205 open={showW09F2205Popup}
                                            mode={_rowData.mode}
                                            rowData={_rowData.row}
                                            FormID="W09F2220"
                                            onClose={(isLoaded) => onCloseModal("W09F2205", isLoaded)}/>}
            <Modal
                open={open}
                maxWidth={"md"}
                fullWidth={true}
                onClose={() => onCloseModal("W09F2200")}
                title={Config.lang("Dieu_chinh") + " " + (rowData?.caption || "")}
            >
                <Modal.Content>
                    <FormGroup>
                        <Row>
                            <Col md={12}>
                                <TextField
                                    label={Config.lang("Thong_tin_hien_tai")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm.OldPropertyValue || ""}
                                    // disabled={isDisabled}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        readOnly: true
                                    }}
                                    onChange={(e) => handleChange("OldPropertyValue", e)}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                        <Row>
                            {isTypeTextBox &&
                            <Col md={12}>
                                <TextField
                                    label={Config.lang("Thong_tin_dieu_chinh")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm.PropertyValue || ""}
                                    // disabled={isDisabled}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type={rowData?.propertyID === "NUMIDCARD" ? "number" : "text"}
                                    onChange={(e) => handleChange("PropertyValue", e)}
                                    fullWidth
                                />
                            </Col>
                            }
                            { isTypeString &&
                            <Col md={12}>
                                <TextField
                                    label={Config.lang("Thong_tin_dieu_chinh")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm.PropertyValue || ""}
                                    // disabled={isDisabled}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        readOnly: true
                                    }}
                                    onChange={(e) => handleChange("PropertyValue", e)}
                                    fullWidth
                                />
                            </Col>
                            }
                            { isTypeDatePicker &&
                            <Col md={12}>
                                <DateBoxPicker
                                    placeholder={"DD/MM/YYYY"}
                                    useMaskBehavior={true}
                                    showClearButton={true}
                                    width={"100%"}
                                    stylingMode={"underlined"}
                                    value={dataForm?.PropertyValue || ""}
                                    onValueChanged={(e) => handleChange("PropertyValue", e)}
                                />
                            </Col>
                            }
                            {isTypeCombo &&
                            <Col md={12}>
                                <Combo
                                    dataSource={dataCboRelative}
                                    displayExpr={"Name"}
                                    valueExpr={"ID"}
                                    stylingMode={"underlined"}
                                    // disabled={isDisabled}
                                    shrink={true}
                                    showClearButton={true}
                                    value={dataForm.PropertyValue || ""}
                                    label={Config.lang("Thong_tin_dieu_chinh")}
                                    onValueChanged={(e) => handleChange("PropertyValue", e)}
                                    margin={"normal"}
                                />
                            </Col>
                            }
                        </Row>
                        <Row>
                            { isTypeString &&
                            <Col md={4}>
                                <TextField
                                    label={Config.lang("So_nha")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm.AddressName || ""}
                                    // disabled={isDisabled}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => handleChange("AddressName", e)}
                                    fullWidth
                                />
                            </Col>
                            }
                            { isTypeString &&
                            <Col md={4}>
                                <Combo
                                    dataSource={dataCboProvinceLabel}
                                    displayExpr={"Name"}
                                    valueExpr={"Code"}
                                    stylingMode={"underlined"}
                                    // disabled={isDisabled}
                                    shrink={true}
                                    showClearButton={true}
                                    value={dataForm.LabelProvince || ""}
                                    label={Config.lang("Thanh_pho")}
                                    onValueChanged={(e) => handleChange("LabelProvince", e)}
                                    margin={"normal"}
                                />
                            </Col>
                            }
                            { isTypeString &&
                            <Col md={4}>
                                <Combo
                                    dataSource={dataCboProvince || []}
                                    displayExpr={"Name"}
                                    valueExpr={"Code"}
                                    stylingMode={"underlined"}
                                    // disabled={isDisabled}
                                    shrink={true}
                                    showClearButton={true}
                                    value={dataForm.ProvinceID || ""}
                                    label={Config.lang("Ten_thanh_pho")}
                                    onValueChanged={(e) => handleChange("ProvinceID", e)}
                                    margin={"normal"}
                                />
                            </Col>
                            }

                        </Row>
                        <Row>
                            { isTypeString &&
                            <Col md={3}>
                                <Combo
                                    dataSource={dataCboLabelDistrict || []}
                                    displayExpr={"Name"}
                                    valueExpr={"Code"}
                                    stylingMode={"underlined"}
                                    // disabled={isDisabled}
                                    shrink={true}
                                    showClearButton={true}
                                    value={dataForm.LabelDistrict || ""}
                                    label={Config.lang("Quan")}
                                    onValueChanged={(e) => handleChange("LabelDistrict", e)}
                                    margin={"normal"}
                                />
                            </Col>
                            }
                            {isTypeString &&
                            <Col md={3}>
                                <Combo
                                    dataSource={dataCboDistrict || []}
                                    displayExpr={"Name"}
                                    valueExpr={"Code"}
                                    stylingMode={"underlined"}
                                    // disabled={isDisabled}
                                    shrink={true}
                                    showClearButton={true}
                                    value={dataForm.DistrictID}
                                    label={Config.lang("Ten_quan")}
                                    onValueChanged={(e) => handleChange("DistrictID", e)}
                                    margin={"normal"}
                                />
                            </Col>
                            }
                            { isTypeString &&
                            <Col md={3}>
                                <Combo
                                    dataSource={dataCboWardLabel || []}
                                    displayExpr={"Name"}
                                    valueExpr={"Code"}
                                    stylingMode={"underlined"}
                                    // disabled={isDisabled}
                                    shrink={true}
                                    showClearButton={true}
                                    value={dataForm.LabelWard || ""}
                                    label={Config.lang("Phuong")}
                                    onValueChanged={(e) => handleChange("LabelWard", e)}
                                    margin={"normal"}
                                />
                            </Col>
                            }
                            { isTypeString &&
                            <Col md={3}>
                                <Combo
                                    dataSource={dataCboWard || []}
                                    displayExpr={"Name"}
                                    valueExpr={"Code"}
                                    stylingMode={"underlined"}
                                    // disabled={isDisabled}
                                    shrink={true}
                                    showClearButton={true}
                                    value={dataForm.WardID || ""}
                                    label={Config.lang("Ten_phuong")}
                                    onValueChanged={(e) => handleChange("WardID", e)}
                                    margin={"normal"}
                                />
                            </Col>
                            }

                        </Row>
                        <Row>
                            <Col md={12}>
                                <TextField
                                    multiline={true}
                                    label={Config.lang("Ghi_chu")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm.Notes || ""}
                                    // disabled={isDisabled}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => handleChange("Notes", e)}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={12}>
                                <Attachments
                                    isAttachInfo
                                    ref={attRef}
                                    showButton={true}
                                    files={dataOldAttachments}
                                    // disabled={isDisabled}
                                    maxLength={5}
                                    uploading={uploading}
                                    onUploading={onUploading}
                                    onChanged={onChangeAttachments}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={12}>
                                <Label className={"mgr5 " + classes.styleLabel}>{Config.lang("Lich_su_dieu_chinh")}</Label>
                                <GridContainer
                                    dataSource={dataGrid.rows}
                                    totalItems={dataGrid.total}
                                    itemPerPage={initFilter.limit}
                                    skipPerPage={initFilter.skip}
                                    typePaging={"remote"}
                                    pagerFullScreen={false}
                                    style={{ border: "none" }}
                                    showRowLines={true}
                                    showBorders={false}
                                    showColumnLines={false}
                                    noDataText={Config.lang("No_data")}
                                    hoverStateEnabled={true}
                                    // loading={gridLoading}
                                >
                                    <Column
                                        // fixed={Config.isMobile ? false : true}
                                        caption={Config.lang("Ngay_dieu_chinh")}
                                        dataField={'TransDate'}
                                        dataType={"date"}
                                        allowEditing={false}
                                        minWidth={180}
                                    />
                                    <Column
                                        caption={Config.lang("Thong_tin_dieu_chinh")}
                                        minWidth={120}
                                        dataField={'PropertyValue'}
                                        allowEditing={false}
                                    />
                                    <Column
                                        caption={Config.lang("Nguoi_duyet")}
                                        dataField={'Approver'}
                                        allowEditing={false}
                                        minWidth={90}
                                    />
                                    <Column
                                        caption={Config.lang("Trang_thai")}
                                        dataField={'AppStatusName'}
                                        allowEditing={false}
                                        minWidth={90}
                                    />
                                    <Column
                                        alignment={"center"}
                                        fixed={true}
                                        fixedPosition={"right"}
                                        allowEditing={false}
                                        cellRender={cellRenderAction}
                                    />
                                </GridContainer>
                            </Col>
                        </Row>
                    </FormGroup>
                </Modal.Content>
                <Modal.Actions style={{ justifyContent: "space-between" }}>
                    <IconButton aria-label="view" disabled={isDisabled} onClick={onAttachment}>
                        <AttachFileIcon />
                    </IconButton>
                    <ButtonGeneral
                        // loading={loading}
                        name={Config.lang("Luu")}
                        typeButton={"save"}
                        // disabled={isDisabled}
                        style={{ textTransform: "uppercase" }}
                        size={"large"}
                        onClick={onSave}
                    />
                </Modal.Actions>
            </Modal>
        </React.Fragment>
    )
};

W09F2200.propTypes = {
    FormID: PropTypes.string,
    open: PropTypes.bool,
    mode: PropTypes.string,
    rowData: PropTypes.object,
    onClose: PropTypes.func,
};

export default compose(
    connect(null,
        (dispatch) => ({
            w09f2200Actions: bindActionCreators(W09F2200Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        })
    ),
)(W09F2200);
