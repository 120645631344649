/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 3/4/2021
 * @Example
 */
import React, {useState, useEffect ,useRef} from "react";
import { connect }                          from "react-redux";
import PropTypes                            from "prop-types";
import * as W09F22215Actions                 from "../../../../redux/W0X/W09F2215/W09F2215_actions";
import * as generalActions                  from "../../../../redux/general/general_actions";
import { bindActionCreators, compose }      from "redux";
import Config                               from "../../../../config";
import {IconButton, TextField} from "@material-ui/core";
import Modal                 from "../../../common/modal/modal";
import {Col, FormGroup, Row} from "react-bootstrap";
import DateBoxPicker         from "../../../common/form-material/date-box";
import {Combo}               from "../../../common/form-material";
import Attachments           from "../../../common/attachments/attachments";
import AttachFileIcon        from "@material-ui/core/SvgIcon/SvgIcon";
import ButtonGeneral         from "../../../common/button/button-general";
import _                     from "lodash";
import moment from "moment";

const W09F2215 = (props) => {

    const _initDataForm = {
        TransID: "",
        OldPropertyValue: "",
        PropertyValue: "",
        RelativeName: "",
        RelationID: "",
        RelationName: "",
        Sex: "",
        SexName: "",
        BirthDate: null,
        RelativePhone: "",
        Notes: "",
        PropertyID: "",
        EmployeeID: "",
    };


    const { rowData, mode, open, onClose, dataGenders, dataListRelative} = props || {};
    const [dataForm, setDataForm] = useState(_initDataForm);
    const [dataOldAttachments, setDataOldAttachments] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [loading, _setLoading] = useState({formLoading: false,});
    const [isDisabled, setIsDisabled] = useState(false);
    const attRef = useRef(null);
    const attachments = useRef([]);
    const deletedFile = useRef([]);

    const loadListRelative = (fieldName) => {
        const params = {
            Language: Config.language || 84,
            FieldName: fieldName
        };
        setLoading({formLoading: true});
        props.w09f2215Actions.getListRelative(params, (error) => {
            setLoading({formLoading: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
        });
    };

    const loadGender = () => {
        setLoading({formLoading: true});
        props.w09f2215Actions.getGender((error) => {
            setLoading({formLoading: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
        });
    };

    const loadForm = () => {
        const params = {
            TransID: _.get(rowData, "TransID", "")
        };
        setLoading({formLoading: true});
        props.w09f2215Actions.getForm(params, (error, data) => {
            setLoading({formLoading: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data) {
                const sexID = data?.Sex ? Number(data?.Sex) : 0;
                const dataSexID = dataGenders.find(item => item.ID === sexID);
                const relationID = _.get(data, "RelationID", "");
                const dataRelationID = dataListRelative.find(item => item.ID === relationID);
                setDataForm({
                    TransID: _.get(data, "TransID", ""),
                    PropertyID: _.get(data, "PropertyID", ""),
                    PropertyValue: _.get(data, "PropertyValueU", ""),
                    EmployeeID: _.get(data, "EmployeeID", ""),
                    RelativeName: _.get(data, "RelativeName", ""),
                    RelationID: relationID,
                    Sex: sexID,
                    RelativePhone: _.get(data, "RelativePhone", ""),
                    Notes: _.get(data, "Notes", ""),
                    OldPropertyValue: _.get(data, "OldPropertyValue", ""),
                    RelationName: _.get(dataRelationID, "Name", ""),
                    SexName: _.get(dataSexID, "Sex", ""),
                    BirthDate: _.get(data, "BirthDate", null),
                    BirthDate1: _.get(data, "BirthDate1", null),
                });
            }
        });
    };

    useEffect(() => {
        if(mode === "edit") {
            loadForm();
            loadGender();
            loadListRelative("RelativeID");
            loadAttachments();
            if(Number(rowData.AppStatusID) !== 0) {
                setIsDisabled(true);
            }
        }
        if(mode === "view") {
            loadForm();
            loadGender();
            loadListRelative("RelativeID");
            loadAttachments();
            setIsDisabled(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode]);

    const setLoading = (obj) => {
        _setLoading({...loading,...obj});
    };

    const setData = (obj) => {
        setDataForm({...dataForm,...obj});
    };

    const loadAttachments = () => {
        const param = {
            KeyID: _.get(rowData, "TransID", ""),
            TableName: "D75T1010"
        };
        setLoading({formLoading: true});
        props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            setLoading({formLoading: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data && data.length > 0) {
                setDataOldAttachments(data);
            }
        });
    };


    const onCloseModal = (isLoaded = false) => {
        if(onClose) onClose(isLoaded);
    };

    const onAttachment = () => {
        if (attRef) {
            attRef.onAttachment();
        }
    };

    const onUploading = (value) => {
        setUploading(value);
    };

    const onChangeAttachments = (e) => {
        attachments.current = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            deletedFile.current = [ ...e.deletedFiles];
            const _arrRemove = deletedFile.current.map((d) => d.AttachmentID);
            setDataOldAttachments(dataOldAttachments.filter((att) => {
                return _arrRemove.indexOf(att.AttachmentID) < 0;
            }));
        }
    };

    const _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(attachments.current);
        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }
        return arrAttachment;
    };

    const formatString = (value) => {
        return value ? ` - ${value}` : "";
    };

    const handleChange = (key,e) => {
        if(!key) return false;
        const { dataListRelative, dataGenders } = props;
        const {RelativeName, RelationName, SexName, BirthDate, RelativePhone} = dataForm;
        let value = _.get(e, "value", _.get(e, "target.value", ""));
        let dataFilter = "";
        let name = "";
        switch (key) {
            case "RelativeName":
                name = value + formatString(RelationName) + formatString(SexName) + formatString(BirthDate) + formatString(RelativePhone);
                setData({RelativeName: value, PropertyValue: name});
                break;
            case "RelationID":
                dataFilter = dataListRelative.find(item => item.ID === value);
                name = RelativeName + formatString(dataFilter?.Name || "") + formatString(SexName) + formatString(BirthDate) + formatString(RelativePhone);
                setData({RelationID: value, RelationName: (dataFilter?.Name || ""), PropertyValue: name});
                break;
            case "Sex":
                dataFilter = dataGenders.find(item => item.ID === value);
                name = RelativeName + formatString(RelationName) + formatString(dataFilter?.Sex || "") + formatString(BirthDate) + formatString(RelativePhone);
                setData({Sex: value, SexName: (dataFilter?.Sex || ""), PropertyValue: name });
                break;
            case "BirthDate":
                name = RelativeName + formatString(RelationName) +  formatString(SexName) + formatString(value) + formatString(RelativePhone);
                setData({BirthDate: value, PropertyValue: name});
                break;
            case "RelativePhone":
                name = RelativeName + formatString(RelationName) + formatString(SexName) + formatString(BirthDate) + formatString(value);
                setData({RelativePhone: value, PropertyValue: name});
                break;
            case "Notes":
                setData({Notes: value});
                break;
            default:
                break;
        }
    };

    const onSave = () => {
        const arrAttachments = _getAttachments();
        const params = {
            TransID: _.get(dataForm, "TransID", ""),
            PropertyID: _.get(dataForm, "PropertyID", ""),
            PropertyValue: _.get(dataForm, "PropertyValue", ""),
            EmployeeID: _.get(dataForm, "EmployeeID", ""),
            RelativeName: _.get(dataForm, "RelativeName", ""),
            RelationID: _.get(dataForm, "RelationID", ""),
            Sex: _.get(dataForm, "Sex", 0),
            BirthDate: _.get(dataForm, "BirthDate", null),
            BirthDate1: dataForm?.BirthDate &&
                        moment.utc(dataForm?.BirthDate).isValid() ?
                        moment.utc(dataForm?.BirthDate) : null,
            RelativePhone: _.get(dataForm, "RelativePhone", ""),
            Notes: _.get(dataForm, "Notes", ""),
            arrAttachment: JSON.stringify(arrAttachments)
        };
        setLoading({formLoading: true});
        props.w09f2215Actions.update(params, (error, data) => {
            setLoading({formLoading: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data) {
                if(data.Status === 1) {
                    let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                } else {
                    Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                    onCloseModal(true);
                }

            }
        });
    };

    return (
        <React.Fragment>
            <Modal
                open={open}
                maxWidth={"md"}
                fullWidth={true}
                onClose={() => onCloseModal(false)}
                title={Config.lang("Chi_tiet_dieu_chinh_thong_tin_gia_dinh")}
            >
                <Modal.Content>
                    <FormGroup>
                        <Row>
                            <Col md={12}>
                                <TextField
                                    label={Config.lang("Thong_tin_hien_tai")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm.OldPropertyValue || ""}
                                    disabled={isDisabled || loading.formLoading}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        readOnly: true
                                    }}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <TextField
                                    label={Config.lang("Thong_tin_dieu_chinh")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm.PropertyValue || ""}
                                    disabled={isDisabled || loading.formLoading}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        readOnly: true
                                    }}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <TextField
                                    label={Config.lang("Ten_nguoi_quan_he")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm.RelativeName || ""}
                                    disabled={isDisabled || loading.formLoading}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => handleChange("RelativeName", e)}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Combo
                                    dataSource={dataListRelative}
                                    displayExpr={"Name"}
                                    valueExpr={"ID"}
                                    stylingMode={"underlined"}
                                    disabled={isDisabled || loading.formLoading}
                                    shrink={true}
                                    showClearButton={true}
                                    value={dataForm.RelationID || ""}
                                    label={Config.lang("Quan_he")}
                                    onValueChanged={(e) => handleChange("RelationID", e)}
                                    margin={"normal"}
                                />
                            </Col>
                            <Col md={6}>
                                <Combo
                                    dataSource={dataGenders}
                                    displayExpr={"Sex"}
                                    valueExpr={"ID"}
                                    stylingMode={"underlined"}
                                    disabled={isDisabled || loading.formLoading}
                                    shrink={true}
                                    showClearButton={true}
                                    value={dataForm.Sex ?? ""}
                                    label={Config.lang("Gioi_tinh")}
                                    onValueChanged={(e) => handleChange("Sex", e)}
                                    margin={"normal"}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <DateBoxPicker
                                    placeholder={"DD/MM/YYYY"}
                                    useMaskBehavior={true}
                                    showClearButton={true}
                                    width={"100%"}
                                    shrink={true}
                                    label={Config.lang("Ngay_sinh")}
                                    margin={"normal"}
                                    disabled={isDisabled || loading.formLoading}
                                    stylingMode={"underlined"}
                                    value={dataForm.BirthDate || ""}

                                    onValueChanged={e => handleChange("BirthDate", e)}
                                />
                            </Col>
                            <Col md={6}>
                                <TextField
                                    label={Config.lang("So_dien_thoai")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm.RelativePhone || ""}
                                    disabled={isDisabled || loading.formLoading}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: 0 }}
                                    type={"number"}
                                    onChange={(e) => handleChange("RelativePhone", e)}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <TextField
                                    multiline={true}
                                    label={Config.lang("Ghi_chu")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm.Notes || ""}
                                    disabled={isDisabled || loading.formLoading}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => handleChange("Notes", e)}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={12}>
                                <Attachments
                                    isAttachInfo
                                    ref={attRef}
                                    showButton={true}
                                    files={dataOldAttachments}
                                    disabled={isDisabled || loading.formLoading}
                                    maxLength={5}
                                    uploading={uploading}
                                    onUploading={onUploading}
                                    onChanged={onChangeAttachments}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                </Modal.Content>
                <Modal.Actions style={{ justifyContent: "space-between" }}>
                    <IconButton aria-label="view"  disabled={isDisabled || loading.formLoading} onClick={onAttachment}>
                        <AttachFileIcon />
                    </IconButton>
                    <ButtonGeneral
                        name={Config.lang("Luu")}
                        typeButton={"save"}
                        disabled={isDisabled || loading.formLoading}
                        style={{ textTransform: "uppercase" }}
                        size={"large"}
                        onClick={onSave}
                    />
                </Modal.Actions>
            </Modal>
        </React.Fragment>
    )
};

W09F2215.propTypes = {
    FormID: PropTypes.string,
    open: PropTypes.bool,
    mode: PropTypes.string,
    rowData: PropTypes.object,
    onClose: PropTypes.func,
};

export default compose(
    connect(
        (state) => ({
            dataGenders: state.W09F2215.getGenders,
            dataListRelative: state.W09F2215.getListRelative,
        }),
        (dispatch) => ({
            w09f2215Actions: bindActionCreators(W09F22215Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        })
    ),
)(W09F2215);
