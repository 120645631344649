/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 3/4/2021
 * @Example
 */
import React, {useState, useEffect ,useRef} from "react";
import { connect }                          from "react-redux";
import PropTypes                            from "prop-types";
import * as W09F2205Actions                 from "../../../../redux/W0X/W09F2205/W09F2205_actions";
import * as generalActions                  from "../../../../redux/general/general_actions";
import { bindActionCreators, compose }      from "redux";
import Config                               from "../../../../config";

import {IconButton, TextField} from "@material-ui/core";

import Modal                 from "../../../common/modal/modal";
import {Col, FormGroup, Row} from "react-bootstrap";
import {Combo}               from "../../../common/form-material";
import Attachments           from "../../../common/attachments/attachments";
import AttachFileIcon        from "@material-ui/core/SvgIcon/SvgIcon";
import ButtonGeneral         from "../../../common/button/button-general";
import _                     from "lodash";
import DateBoxPicker         from "../../../common/form-material/date-box";
import moment                from "moment";

const W09F2205 = (props) => {

    const _initDataForm = {
        TransID: "",
        PropertyID: "",
        PropertyName: "",
        EmployeeID: "",
        OldPropertyValue: "",
        Notes: "",
        PropertyValue: "",
        AddressName: "",
        LabelProvince: "",
        LabelProvinceName: "",
        ProvinceID: "",
        ProvinceName: "",
        LabelDistrict: "",
        LabelDistrictName: "",
        DistrictID: "",
        DistrictName: "",
        LabelWard: "",
        LabelWardName: "",
        WardID: "",
        WardName: "",
    };
    const { rowData, mode, open, onClose} = props || {};
    const [dataForm, setDataForm] = useState(_initDataForm);
    const [dataCboProvinceLabel, setDataCboProvinceLabel] = useState([]);
    const [dataCboProvince, setDataCboProvince] = useState([]);
    const [dataCboDistrict, setDataCboDistrict] = useState([]);
    const [dataCboLabelDistrict, setDataCboLabelDistrict] = useState([]);
    const [dataCboWardLabel, setDataCboWardLabel] = useState([]);
    const [dataCboWard, setDataCboWard] = useState([]);
    const [dataOldAttachments, setDataOldAttachments] = useState([]);
    const [dataCboRelative, setDataCboRelative] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [loading, _setLoading] = useState({formLoading: false});
    const [isDisabled, setIsDisabled] = useState(false);
    const [isTypeTextBox, setIsTypeTextBox] = useState(false);
    const [isTypeString, setIsTypeString] = useState(false);
    const [isTypeDatePicker, setIsTypeDatePicker] = useState(false);
    const [isTypeCombo, setIsTypeCombo] = useState(false);
    const attRef = useRef(null);
    const attachments = useRef([]);
    const deletedFile = useRef([]);
    const isSetStated = useRef(false);
    const isChanged = useRef(false);

    const loadForm = () => {
        const params = {
            TransID:  _.get(rowData, "TransID", "")
        };
        setLoading({formLoading: true});
        props.w09f2205Actions.getForm(params, (error, data) => {
            setLoading({formLoading: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data) {
                setDataForm({
                    TransID: _.get(data, "TransID", ""),
                    PropertyID: _.get(data, "PropertyID", ""),
                    PropertyName: _.get(data, "PropertyName", ""),
                    EmployeeID: _.get(data, "EmployeeID", ""),
                    OldPropertyValue: _.get(data, "OldPropertyValue", ""),
                    Notes: _.get(data, "Notes", ""),
                    PropertyValue: _.get(data, "PropertyValueU", ""),
                    AddressName: _.get(data, "AddressName", ""),
                    LabelProvince: _.get(data, "LabelProvince", ""),
                    ProvinceID: _.get(data, "ProvinceID", ""),
                    LabelDistrict: _.get(data, "LabelDistrict", ""),
                    DistrictID: _.get(data, "DistrictID", ""),
                    LabelWard: _.get(data, "LabelWard", ""),
                    WardID: _.get(data, "WardID", ""),
                    LabelProvinceName: "",
                    ProvinceName: "",
                    LabelDistrictName: "",
                    DistrictName: "",
                    LabelWardName: "",
                    WardName: "",
                })
            }
        });
    };

    const loadCboAddress = (sourceName = "", parentID = "") => {
        const params = {
            Language: Config.language || 84,
            CodeTable: 1,
            SourceName: sourceName,
            ParentID: parentID,
        };
        setLoading({formLoading: true});
        props.w09f2205Actions.getCboAddress(params, (error, data) => {
            setLoading({formLoading: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data) {
                _setDataCbo(sourceName,data);
                if((sourceName === "QUAN/HUYEN" || sourceName === "XA/PHUONG") && mode === "edit" && isChanged.current) {
                    if(data && data.length > 0) {
                        const { AddressName, LabelProvinceName,DistrictName, ProvinceName, LabelDistrictName, LabelWardName, WardName} = dataForm;
                        const Code = _.get(data[0], "Code", "");
                        let dataFilter = "";
                        let name = "";
                        if(sourceName === "XA/PHUONG") {
                            isChanged.current = false;
                            dataFilter = data.find(item => item.Code === Code);
                            name = AddressName + " " + LabelWardName + " " + (dataFilter?.Name || "") + " " +
                                LabelDistrictName + " " + DistrictName + " " + LabelProvinceName + " " + ProvinceName;
                            setData({WardID: Code, WardName: dataFilter?.Name || "", PropertyValue: name});
                        } else {
                            dataFilter = data.find(item => item.Code === Code);
                            name = AddressName + " " + LabelWardName + " " + WardName + " " + LabelDistrictName + " " +
                                (dataFilter?.Name || "") + " " + LabelProvinceName + " " + ProvinceName;
                            setData({DistrictID: Code, DistrictName: dataFilter?.Name || "", PropertyValue: name});
                        }

                    }
                }
            }
        });
    };

    const _setDataCbo = (sourceName,data) => {
        switch (sourceName) {
            case "ProvinceLabel":
                setDataCboProvinceLabel(data);
                break;
            case "TINH/THANH":
                setDataCboProvince(data);
                break;
            case "DistrictLabel":
                setDataCboLabelDistrict(data);
                break;
            case "WardLabel":
                setDataCboWardLabel(data);
                break;
            case "QUAN/HUYEN":
                setDataCboDistrict(data);
                break;
            case "XA/PHUONG":
                setDataCboWard(data);
                break;
            default:
                break;
        }
    };

    const loadListRelative = (fieldName = "") => {
        const params = {
            Language: Config.language || 84,
            FieldName: fieldName
        };
        setLoading({formLoading: true});
        props.w09f2205Actions.getListRelative(params, (error, data) => {
            setLoading({formLoading: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if(data) {
                setDataCboRelative(data);
            }
        });
    };

    const getFieldName = (fieldName) => {
        switch (fieldName) {
            case "ETHNIC":
                return "EthnicID";
            case "RELIGION":
                return "ReligionID";
            case "COUNTRY":
                return "CountryID";
            case "NUMIDPLACE":
                return "IDCardPlace";
            case "IDCARDPLACE":
                return "IDCardPlace";
            case "PASSPORTPLACE":
                return "CountryID";
            case "EDUCATION":
                return "EducationLevelID";
            case "PROFESSIONAL":
                return "ProfessionalLevelID";
            case "MAJOR":
                return "MajorID";
            case "POLITICS":
                return "PoliticsID";
            case "EMCONREL1":
                return "EmRelationID";
            case "EMCONREL2":
                return "EmRelationID";
            case "NATIVEPLACE":
                return "IDCardPlace";
            case "MARITALSTATUS":
                return "MaritalStatus";
            default:
                return "";
        }
    };

    const checkTypePropertyValue = (key) => {
        if(!key) return false;
        switch (key) {
            case "NUMIDCARD":
            case "IDCARD":
            case "PASSPORT":
            case "TEL":
            case "MOBILE":
            case "CompanyTEL":
            case "EMAIL":
            case "EMCONNAME1":
            case "EMCONPHONE1":
            case "EMCONNAME2":
            case "EMCONPHONE2":
                setIsTypeTextBox(true);
                break;
            case "CONADDR":
            case "PERADDR":
            case "PROADDR":
            case "EMCONADD1":
            case "EMCONADD2":
                loadCboAddress("ProvinceLabel", "");
                loadCboAddress("TINH/THANH", "");
                loadCboAddress("DistrictLabel", "");
                loadCboAddress("WardLabel", "");
                setIsTypeString(true);
                break;
            case "NUMIDDATE":
            case "IDCARDDATE":
            case "PASSPORTBEGIN":
            case "PASSPORTEND":
                setIsTypeDatePicker(true);
                break;
            case "ETHNIC":
            case "RELIGION":
            case "COUNTRY":
            case "NUMIDPLACE":
            case "IDCARDPLACE":
            case "PASSPORTPLACE":
            case "EDUCATION":
            case "PROFESSIONAL":
            case "MAJOR":
            case "POLITICS":
            case "EMCONREL1":
            case "EMCONREL2":
            case "NATIVEPLACE":
            case "MARITALSTATUS":
                loadListRelative(_.get(rowData, "fieldName", getFieldName(key) || ""));
                setIsTypeCombo(true);
                break;
            default:
                break;

        }
    };

    useEffect(() => {
        if(mode === "edit") {
            checkTypePropertyValue(_.get(rowData, "propertyID", _.get(rowData, "PropertyID", "")));
            loadForm();
            loadAttachments();
            if(Number(rowData.AppStatusID) !== 0) {
                setIsDisabled(true);
            }
        }

        if(mode === "view") {
            checkTypePropertyValue(_.get(rowData, "propertyID", _.get(rowData, "PropertyID", "")));
            loadForm();
            loadAttachments();
            setIsDisabled(true);
        }
        if(mode === "view") {
            checkTypePropertyValue(_.get(rowData, "propertyID", _.get(rowData, "PropertyID", "")));
            loadForm();
            loadAttachments();
            setIsDisabled(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode]);

    useEffect(() => {
        if(!!_.get(dataForm, "ProvinceID", "")) {
            loadCboAddress("QUAN/HUYEN",_.get(dataForm, "ProvinceID", ""));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm?.ProvinceID]);

    useEffect(() => {
        if(!!_.get(dataForm, "DistrictID", "")) {
            loadCboAddress("XA/PHUONG",_.get(dataForm, "DistrictID", ""));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm?.DistrictID]);

    useEffect(() => {
        if(mode === "edit" && !isSetStated.current && !_.isEmpty(dataForm) && !_.isEmpty(dataCboProvinceLabel) &&
            !_.isEmpty(dataCboProvince) && !_.isEmpty(dataCboLabelDistrict) &&
            !_.isEmpty(dataCboDistrict) && !_.isEmpty(dataCboWardLabel) &&
            !_.isEmpty(dataCboWardLabel) && !_.isEmpty(dataCboWard)) {
            isSetStated.current = true;
            const LabelProvince = dataCboProvinceLabel.find(item =>
                item.ID === _.get(dataForm, "LabelProvince", ""));
            const ProvinceID = dataCboProvince.find(item =>
                item.Code === _.get(dataForm, "ProvinceID", ""));
            const LabelDistrict = dataCboLabelDistrict.find(item =>
                item.ID === _.get(dataForm, "LabelDistrict", ""));
            const DistrictID = dataCboDistrict.find(item =>
                item.Code === _.get(dataForm, "DistrictID", ""));
            const LabelWard = dataCboWardLabel.find(item =>
                item.ID === _.get(dataForm, "LabelWard", ""));
            const WardID = dataCboWard.find(item =>
                item.Code === _.get(dataForm, "WardID", ""));
            setData({
                LabelProvinceName: LabelProvince?.Name || "",
                ProvinceName: ProvinceID?.Name || "",
                LabelDistrictName: LabelDistrict?.Name || "",
                DistrictName: DistrictID?.Name || "",
                LabelWardName: LabelWard?.Name || "",
                WardName: WardID?.Name || "",
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataForm, dataCboProvinceLabel, dataCboProvince, dataCboLabelDistrict,
        dataCboDistrict, dataCboWardLabel, dataCboWard]);


    const setLoading = (obj) => {
        _setLoading({...loading,...obj});
    };

    const setData = (obj) => {
        setDataForm({...dataForm,...obj});
    };

    const loadAttachments = () => {
        const param = {
            KeyID: _.get(rowData, "TransID", ""),
            TableName: "D75T1010"
        };
        setLoading({formLoading: true});
        props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            setLoading({formLoading: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data && data.length > 0) {
                setDataOldAttachments(data);
            }
        });
    };


    const onCloseModal = (isLoaded = false) => {
        if(onClose) onClose(isLoaded);
    };

    const onAttachment = () => {
        if (attRef) {
            attRef.onAttachment();
        }
    };

    const onUploading = (value) => {
        setUploading(value);
    };

    const onChangeAttachments = (e) => {
        attachments.current = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            deletedFile.current = [ ...e.deletedFiles];
            const _arrRemove = deletedFile.current.map((d) => d.AttachmentID);
            setDataOldAttachments(dataOldAttachments.filter((att) => {
                return _arrRemove.indexOf(att.AttachmentID) < 0;
            }));
        }
    };

    const _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(attachments.current);
        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    const handleChange = (key,e) => {
        if(!key) return false;
        if(!isChanged.current) {
            isChanged.current = true;
        }
        let value = _.get(e, "value", _.get(e, "target.value", ""));
        switch (key) {
            case "PropertyValue":
                _setName(key,value);
                break;
            case "AddressName":
                _setName(key,value);
                break;
            case "LabelProvince":
                _setName(key,value);
                break;
            case "ProvinceID":
                _setName(key,value);
                break;
            case "LabelDistrict":
                _setName(key,value);
                break;
            case "DistrictID":
                _setName(key,value);
                break;
            case "LabelWard":
                _setName(key,value);
                break;
            case "WardID":
                _setName(key,value);
                break;
            case "Notes":
                _setName(key,value);
                break;
            default:
                break;
        }
    };

    const _setName = (key,value) => {
        const { AddressName, LabelProvinceName,DistrictName, ProvinceName, LabelDistrictName, LabelWardName, WardName} = dataForm;
        let dataFilter = "";
        let name = "";
        switch (key) {
            case "PropertyValue":
                setData({PropertyValue: value});
                break;
            case "AddressName":
                name = value + " " + LabelWardName + " " + WardName + " " + LabelDistrictName + " " +
                    DistrictName + " " + LabelProvinceName + " " + ProvinceName;
                setData({AddressName: value, PropertyValue: name});
                break;
            case "LabelProvince":
                dataFilter = dataCboProvinceLabel.find(item => item.ID === value);
                name = AddressName + " " + LabelWardName + " " + WardName + " " + LabelDistrictName +
                    " " + DistrictName + " " + (dataFilter?.Name || "") + " " + ProvinceName;
                setData({LabelProvince: value, LabelProvinceName: dataFilter?.Name || "", PropertyValue: name});
                break;
            case "ProvinceID":
                dataFilter = dataCboProvince.find(item => item.Code === value);
                name = AddressName + " " + LabelWardName + " " + WardName + " " + LabelDistrictName + " " +
                    DistrictName + " " + LabelProvinceName + " " + (dataFilter?.Name || "");
                setData({ProvinceID: value,ProvinceName: dataFilter?.Name || "", PropertyValue: name});
                break;
            case "LabelDistrict":
                dataFilter = dataCboLabelDistrict.find(item => item.ID === value);
                name = AddressName + " " + LabelWardName + " " + WardName + " " + (dataFilter?.Name || "") + " " +
                    DistrictName + " " + LabelProvinceName + " " + ProvinceName;
                setData({LabelDistrict: value, LabelDistrictName: dataFilter?.Name || "", PropertyValue: name});
                break;
            case "DistrictID":
                dataFilter = dataCboDistrict.find(item => item.Code === value);
                name = AddressName + " " + LabelWardName + " " + WardName + " " + LabelDistrictName + " " +
                    (dataFilter?.Name || "") + " " + LabelProvinceName + " " + ProvinceName;
                setData({DistrictID: value, DistrictName: dataFilter?.Name || "", PropertyValue: name});
                break;
            case "LabelWard":
                dataFilter = dataCboWardLabel.find(item => item.ID === value);
                name =  AddressName + " " + (dataFilter?.Name || "") + " " + WardName + " " + LabelDistrictName + " " +
                    DistrictName + " " + LabelProvinceName + " " + ProvinceName;
                setData({LabelWard: value, LabelWardName: dataFilter?.Name || "", PropertyValue: name});
                break;
            case "WardID":
                dataFilter = dataCboWard.find(item => item.Code === value);
                name = AddressName + " " + LabelWardName + " " + (dataFilter?.Name || "") + " " +
                    LabelDistrictName + " " + DistrictName + " " + LabelProvinceName + " " + ProvinceName;
                setData({WardID: value, WardName: dataFilter?.Name || "", PropertyValue: name});
                break;
            case "Notes":
                setData({Notes: value});
                break;
            default:
                break;
        }
    };
    const onSave = () => {
        const arrAttachments = _getAttachments();
        const params = {
            TransID: _.get(dataForm, "TransID", ""),
            PropertyID: _.get(dataForm, "PropertyID", ""),
            PropertyName: _.get(dataForm, "PropertyName", ""),
            EmployeeID: _.get(dataForm, "EmployeeID", ""),
            OldPropertyValue: dataForm?.OldPropertyValue ?
                (isTypeDatePicker ? moment(dataForm?.OldPropertyValue, "DD/MM/YYYY").format("YYYY-MM-DD") : dataForm.OldPropertyValue)  : null,
            AddressName: _.get(dataForm, "AddressName", ""),
            LabelProvince: _.get(dataForm, "LabelProvince", ""),
            ProvinceID: _.get(dataForm, "ProvinceID", ""),
            LabelDistrict: _.get(dataForm, "LabelDistrict", ""),
            DistrictID: _.get(dataForm, "DistrictID", ""),
            LabelWard: _.get(dataForm, "LabelWard", ""),
            WardID: _.get(dataForm, "WardID", ""),
            PropertyValue: _.get(dataForm, "PropertyValue", ""),
            arrAttachment: JSON.stringify(arrAttachments)
        };
        setLoading({formLoading: true});
        props.w09f2205Actions.update(params, (error, data) => {
            setLoading({formLoading: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data) {
                if(data.Status === 1) {
                    let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }
                if(data.Status === 0) {
                    Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                    onCloseModal(true);
                }

            }
        });
    };

    return (
        <React.Fragment>
            <Modal
                open={open}
                maxWidth={"md"}
                fullWidth={true}
                onClose={() => onCloseModal(false)}
                title={Config.lang("Chi_tiet_dieu_chinh") + " " + _.get(dataForm, "PropertyName", "")}
            >
                <Modal.Content>
                    <FormGroup>
                        <Row>
                            <Col md={12}>
                                <TextField
                                    label={Config.lang("Thong_tin_hien_tai")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm.OldPropertyValue || ""}
                                    disabled={isDisabled || loading.formLoading}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        readOnly: true
                                    }}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                        <Row>
                            {isTypeTextBox &&
                            <Col md={12}>
                                <TextField
                                    label={Config.lang("Thong_tin_dieu_chinh")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm.PropertyValue || ""}
                                    disabled={isDisabled || loading.formLoading}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    type={rowData?.propertyID === "NUMIDCARD" ? "number" : "text"}
                                    onChange={(e) => handleChange("PropertyValue", e)}
                                    fullWidth
                                />
                            </Col>
                            }
                            { isTypeString &&
                            <Col md={12}>
                                <TextField
                                    label={Config.lang("Thong_tin_dieu_chinh")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm?.PropertyValue || ""}
                                    disabled={isDisabled || loading.formLoading}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        readOnly: true
                                    }}
                                    onChange={(e) => handleChange("PropertyValue", e)}
                                    fullWidth
                                />
                            </Col>
                            }
                            { isTypeDatePicker &&
                            <Col md={12}>
                                <DateBoxPicker
                                    placeholder={"DD/MM/YYYY"}
                                    useMaskBehavior={true}
                                    showClearButton={true}
                                    width={"100%"}
                                    disabled={isDisabled || loading.formLoading}
                                    stylingMode={"underlined"}
                                    value={dataForm?.PropertyValue || ""}
                                    onValueChanged={(e) => handleChange("PropertyValue", e)}
                                />
                            </Col>
                            }
                            {isTypeCombo &&
                            <Col md={12}>
                                <Combo
                                    dataSource={dataCboRelative}
                                    displayExpr={"Name"}
                                    valueExpr={"ID"}
                                    stylingMode={"underlined"}
                                    disabled={isDisabled || loading.formLoading}
                                    shrink={true}
                                    showClearButton={true}
                                    value={dataForm?.PropertyValue || ""}
                                    label={Config.lang("Thong_tin_dieu_chinh")}
                                    onValueChanged={(e) => handleChange("PropertyValue", e)}
                                    margin={"normal"}
                                />
                            </Col>
                            }
                        </Row>
                        <Row>
                            { isTypeString &&
                            <Col md={4}>
                                <TextField
                                    label={Config.lang("So_nha")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm?.AddressName || ""}
                                    disabled={isDisabled || loading.formLoading}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => handleChange("AddressName", e)}
                                    fullWidth
                                />
                            </Col>
                            }
                            { isTypeString &&
                            <Col md={4}>
                                <Combo
                                    dataSource={dataCboProvinceLabel}
                                    displayExpr={"Name"}
                                    valueExpr={"Code"}
                                    stylingMode={"underlined"}
                                    disabled={isDisabled || loading.formLoading}
                                    shrink={true}
                                    showClearButton={true}
                                    value={dataForm?.LabelProvince || ""}
                                    label={Config.lang("Thanh_pho")}
                                    onValueChanged={(e) => handleChange("LabelProvince", e)}
                                    margin={"normal"}
                                />
                            </Col>
                            }
                            { isTypeString &&
                            <Col md={4}>
                                <Combo
                                    dataSource={dataCboProvince || []}
                                    displayExpr={"Name"}
                                    valueExpr={"Code"}
                                    stylingMode={"underlined"}
                                    disabled={isDisabled || loading.formLoading}
                                    shrink={true}
                                    showClearButton={true}
                                    value={dataForm?.ProvinceID || ""}
                                    label={Config.lang("Ten_thanh_pho")}
                                    onValueChanged={(e) => handleChange("ProvinceID", e)}
                                    margin={"normal"}
                                />
                            </Col>
                            }

                        </Row>
                        <Row>
                            { isTypeString &&
                            <Col md={3}>
                                <Combo
                                    dataSource={dataCboLabelDistrict || []}
                                    displayExpr={"Name"}
                                    valueExpr={"Code"}
                                    stylingMode={"underlined"}
                                    disabled={isDisabled || loading.formLoading}
                                    shrink={true}
                                    showClearButton={true}
                                    value={dataForm?.LabelDistrict || ""}
                                    label={Config.lang("Quan")}
                                    onValueChanged={(e) => handleChange("LabelDistrict", e)}
                                    margin={"normal"}
                                />
                            </Col>
                            }
                            {isTypeString &&
                            <Col md={3}>
                                <Combo
                                    dataSource={dataCboDistrict || []}
                                    displayExpr={"Name"}
                                    valueExpr={"Code"}
                                    stylingMode={"underlined"}
                                    disabled={isDisabled || loading.formLoading}
                                    shrink={true}
                                    showClearButton={true}
                                    value={dataForm?.DistrictID || ""}
                                    label={Config.lang("Ten_quan")}
                                    onValueChanged={(e) => handleChange("DistrictID", e)}
                                    margin={"normal"}
                                />
                            </Col>
                            }
                            { isTypeString &&
                            <Col md={3}>
                                <Combo
                                    dataSource={dataCboWardLabel || []}
                                    displayExpr={"Name"}
                                    valueExpr={"Code"}
                                    stylingMode={"underlined"}
                                    disabled={isDisabled || loading.formLoading}
                                    shrink={true}
                                    showClearButton={true}
                                    value={dataForm.LabelWard || ""}
                                    label={Config.lang("Phuong")}
                                    onValueChanged={(e) => handleChange("LabelWard", e)}
                                    margin={"normal"}
                                />
                            </Col>
                            }
                            { isTypeString &&
                            <Col md={3}>
                                <Combo
                                    dataSource={dataCboWard || []}
                                    displayExpr={"Name"}
                                    valueExpr={"Code"}
                                    stylingMode={"underlined"}
                                    disabled={isDisabled || loading.formLoading}
                                    shrink={true}
                                    showClearButton={true}
                                    value={dataForm.WardID || ""}
                                    label={Config.lang("Ten_phuong")}
                                    onValueChanged={(e) => handleChange("WardID", e)}
                                    margin={"normal"}
                                />
                            </Col>
                            }

                        </Row>
                        <Row>
                            <Col md={12}>
                                <TextField
                                    multiline={true}
                                    label={Config.lang("Ghi_chu")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm.Notes || ""}
                                    disabled={isDisabled || loading.formLoading}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => handleChange("Notes", e)}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={12}>
                                <Attachments
                                    isAttachInfo
                                    ref={attRef}
                                    showButton={true}
                                    files={dataOldAttachments}
                                    disabled={isDisabled || loading.formLoading}
                                    maxLength={5}
                                    uploading={uploading}
                                    onUploading={onUploading}
                                    onChanged={onChangeAttachments}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                </Modal.Content>
                <Modal.Actions style={{ justifyContent: "space-between" }}>
                    <IconButton aria-label="view" disabled={isDisabled || loading.formLoading} onClick={onAttachment}>
                        <AttachFileIcon />
                    </IconButton>
                    <ButtonGeneral
                        // loading={loading}
                        name={Config.lang("Luu")}
                        typeButton={"save"}
                        disabled={isDisabled || loading.formLoading}
                        style={{ textTransform: "uppercase" }}
                        size={"large"}
                        onClick={onSave}
                    />
                </Modal.Actions>
            </Modal>
        </React.Fragment>
    )
};

W09F2205.propTypes = {
    FormID: PropTypes.string,
    open: PropTypes.bool,
    mode: PropTypes.string,
    rowData: PropTypes.object,
    onClose: PropTypes.func,
};

export default compose(
    connect(null,
        (dispatch) => ({
            w09f2205Actions: bindActionCreators(W09F2205Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        })
    ),
)(W09F2205);
