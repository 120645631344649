/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 3/4/2021
 * @Example
 */
import React, {useState, useEffect ,useRef}                    from "react";
import { connect }                                             from "react-redux";
import PropTypes                                               from "prop-types";
import * as W09F2210Actions                                    from "../../../../redux/W0X/W09F2210/W09F2210_actions";
import * as generalActions                                     from "../../../../redux/general/general_actions";
import { bindActionCreators, compose }                         from "redux";
import Config                                                  from "../../../../config";
import {FormLabel as Label, IconButton, makeStyles, TextField} from "@material-ui/core";
import Modal                                                   from "../../../common/modal/modal";
import {Col, FormGroup, Row}                                   from "react-bootstrap";
import DateBoxPicker                                           from "../../../common/form-material/date-box";
import {Combo}                                                 from "../../../common/form-material";
import Attachments                                             from "../../../common/attachments/attachments";
import AttachFileIcon                                          from "@material-ui/core/SvgIcon/SvgIcon";
import ButtonGeneral from "../../../common/button/button-general";
import _             from "lodash";
import GridContainer from "../../../grid-container/grid-container";
import {Column}      from "devextreme-react/data-grid";
import GridActionBar from "../../../grid-container/grid-actionbar";
import Icons         from "../../../common/icons";
import HistoryIcon   from "@material-ui/icons/History"
import W84F3005      from "../../../W8X/W84/W84F3005/W84F3005";
import moment        from "moment";
import W09F2215      from "../W09F2215/W09F2215";

const useStyles = makeStyles(() => ({
    styleLabel: {
        fontStyle: 14,
        fontWeight: 700,
        color: "#111D5E"
    }
}));

const W09F2210 = (props) => {

    const _initDataForm = {
        OldPropertyValue: "",
        PropertyValue: "",
        RelativeName: "",
        RelationID: "",
        RelationName: "",
        Sex: "",
        SexName: "",
        BirthDate: null,
        RelativePhone: "",
        Notes: "",
        PropertyID: "",
        EmployeeID: "",
        IGE: "",
        RelativeID: "",
    };

    const initDataGrid = {
        rows: [],
        total: 0
    };

    const initFilter = {
        skip: 0,
        limit: 10
    };

    const _w84F3005PopupData = {
        TransID: null
    };

    const _initRowData = {
        row: {},
        mode: ""
    };

    const { rowData, open, onClose, dataGenders, dataListRelative} = props || {};
    const [dataForm, setDataForm] = useState(_initDataForm);
    const [dataGrid, setDataGrid] = useState(initDataGrid);
    const [dataOldAttachments, setDataOldAttachments] = useState([]);
    const [_rowData, setRowData] = useState(_initRowData);
    const [uploading, setUploading] = useState(false);
    const [loading, _setLoading] = useState({formLoading: false, loadingListRelative: false, loadingGender: false});
    const [showW84F3005Popup, setShowW84F3005Popup] = useState(false);
    const [showW09F2215Popup, setShowW09F2215Popup] = useState(false);
    const attRef = useRef(null);
    const attachments = useRef([]);
    const deletedFile = useRef([]);
    const w84F3005PopupData = useRef(_w84F3005PopupData);
    const classes = useStyles();

    const loadListRelative = (fieldName) => {
        const params = {
            Language: Config.language || 84,
            FieldName: fieldName
        };
        setLoading({formLoading: true});
        props.w09f2210Actions.getListRelative(params, (error) => {
            setLoading({formLoading: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
        });
    };

    const loadGender = () => {
        setLoading({formLoading: true});
        props.w09f2210Actions.getGender((error) => {
            setLoading({formLoading: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
        });
    };

    const loadGrid = () => {
        const params = {
            TransID: "",
            Language: Config.language || 84,
            FormID: "W09F2210",
            EmployeeID: _.get(rowData, "employeeID", ""),
            PropertyID: _.get(rowData, "propertyID", ""),
            skip: initFilter.skip,
            limit: initFilter.limit,
        };
        setLoading({formLoading: true});
        props.w09f2210Actions.getDetail(params, (error, data) => {
            setLoading({formLoading: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data) {
                setDataGrid({
                    total: data.total,
                    rows: data.rows
                });
            }
        });
    };

    useEffect(() => {
        loadGrid();
        loadGender();
        loadListRelative("RelativeID");
        loadAttachments();
        setData({
            OldPropertyValue: _.get(rowData, "oldPropertyID", ""),
            RelativeID: _.get(rowData, "RelativeID", ""),
            IGE: _.get(rowData, "IGE", ""),
            EmployeeID: _.get(rowData, "employeeID", ""),
            PropertyID: _.get(rowData, "propertyID", "")
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setLoading = (obj) => {
        _setLoading({...loading,...obj});
    };

    const setData = (obj) => {
        setDataForm({...dataForm,...obj});
    };

    const loadAttachments = () => {
        const param = {
            KeyID:  _.get(rowData, "propertyID", ""),
            TableName: "D75T1010"
        };
        setLoading({formLoading: true});
        props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            setLoading({formLoading: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data && data.length > 0) {
                setDataOldAttachments(data);
            }
        });
    };


    const onCloseModal = (formID) => {
        if(formID === "W09F2215") {
            setShowW09F2215Popup(false);
        }
        if(formID === "W09F2210") {
            onClose && onClose();
        }
    };

    const onHistoryApproval = (e, data = {}) => {
        w84F3005PopupData.current.TransID = data.TransID;
        setShowW84F3005Popup(true);
    };

    const onAttachment = () => {
        if (attRef) {
            attRef.onAttachment();
        }
    };

    const onUploading = (value) => {
        setUploading(value);
    };

    const onChangeAttachments = (e) => {
        attachments.current = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            deletedFile.current = [ ...e.deletedFiles];
            const _arrRemove = deletedFile.current.map((d) => d.AttachmentID);
            setDataOldAttachments(dataOldAttachments.filter((att) => {
                return _arrRemove.indexOf(att.AttachmentID) < 0;
            }));
        }
    };

    const _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(attachments.current);
        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }
        return arrAttachment;
    };

    const handleChange = (key,e) => {
        if(!key) return false;
        let value = _.get(e, "value", _.get(e, "target.value", ""));
        switch (key) {
            case "RelativeName":
                _setName(key,value);
                break;
            case "RelationID":
                _setName(key,value);
                break;
            case "Sex":
                _setName(key,value);
                break;
            case "BirthDate":
                _setName(key,value);
                break;
            case "RelativePhone":
                _setName(key,value);
                break;
            case "Notes":
                _setName(key,value);
                break;
            default:
                break;
        }
    };
    const formatString = (value) => {
        return value ? ` - ${value}` : "";
    };

    const _setName = (key,value) => {
        const { dataListRelative, dataGenders } = props;
        const {RelativeName, RelationName, SexName, BirthDate, RelativePhone} = dataForm;
        let dataFilter = "";
        let name = "";
        switch (key) {
            case "RelativeName":
                name = value + formatString(RelationName) + formatString(SexName) + formatString(BirthDate) + formatString(RelativePhone);
                setData({RelativeName: value, PropertyValue: name});
                break;
            case "RelationID":
                dataFilter = dataListRelative.find(item => item.ID === value);
                name = RelativeName + formatString(dataFilter?.Name || "") + formatString(SexName) + formatString(BirthDate) + formatString(RelativePhone);
                setData({RelationID: value, RelationName: (dataFilter?.Name || ""), PropertyValue: name});
                break;
            case "Sex":
                dataFilter = dataGenders.find(item => item.ID === value);
                name = RelativeName + formatString(RelationName) + formatString(dataFilter?.Sex || "") + formatString(BirthDate) + formatString(RelativePhone);
                setData({Sex: value, SexName: (dataFilter?.Sex || ""), PropertyValue: name });
                break;
            case "BirthDate":
                name = RelativeName + formatString(RelationName) +  formatString(SexName) + formatString(value) + formatString(RelativePhone);
                setData({BirthDate: value, PropertyValue: name});
                break;
            case "RelativePhone":
                name = RelativeName + formatString(RelationName) + formatString(SexName) + formatString(BirthDate) + formatString(value);
                setData({RelativePhone: value, PropertyValue: name});
                break;
            case "Notes":
                setData({Notes: value});
                break;
            default:
                break;
        }
    };

    const onSave = () => {
        const arrAttachments = _getAttachments();
        const params = {
            PropertyID: _.get(dataForm, "PropertyID", ""),
            PropertyValue: _.get(dataForm, "PropertyValue", ""),
            EmployeeID: _.get(dataForm, "EmployeeID", ""),
            OldPropertyValue: _.get(dataForm, "OldPropertyValue", ""),
            Notes: _.get(dataForm, "Notes", ""),
            RelativeName: _.get(dataForm, "RelativeName", ""),
            RelationID: _.get(dataForm, "RelationID", ""),
            Sex: _.get(dataForm, "Sex", ""),
            BirthDate: _.get(dataForm, "BirthDate", null),
            BirthDate1:  dataForm?.BirthDate &&
                         moment.utc(dataForm?.BirthDate).isValid() ?
                         moment.utc(dataForm?.BirthDate) : null,
            RelativePhone: _.get(dataForm, "RelativePhone", ""),
            RelativeID: _.get(dataForm, "RelativeID", ""),
            IGE: _.get(dataForm, "IGE", ""),
            arrAttachment: JSON.stringify(arrAttachments)
        };
        setLoading({formLoading: true});
        props.w09f2210Actions.save(params, (error, data) => {
            setLoading({formLoading: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data) {
                if(data.Status === 1) {
                    let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }
                if(data.Status === 0) {
                    Config.notify.show('success', Config.lang("Luu_thanh_cong"), 2000);
                    onClose && onClose();
                }

            }
        });
    };

    const onEdit = (e) => {
        const {data} = e.row;
        const date = data?.BirthDate && moment(data?.BirthDate).format("DD/MM/YYYY");
        let oldPropertyID = (data?.RelativeName || "") + " - " + (data?.RelationName || "") +
            " - " + (data?.SexName || "") + " - " + (date || "") + " - " + (data?.Telephone || "");
        setRowData({row: {
                TransID: _.get(data, "TransID", ""),
                propertyID: "RELATIVE",
                oldPropertyID: oldPropertyID,
                relativeID: _.get(data, "RelativeID", ""),
                employeeID: _.get(dataForm, "EmployeeID", ""),
                AppStatusID:  _.get(data, "AppStatusID", "")
            }, mode: "edit"});
        setShowW09F2215Popup(true);
    };

    const onDelete = (data) => {
        const params = {
            TransID: _.get(data, "TransID", "")
        };
        setLoading({formLoading: true});
        props.w09f2210Actions.deleteData(params, (error, data) => {
            setLoading({formLoading: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if (data) {
                if(data.Status === 1) {
                    let message = data.message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show('INFO', message);
                    return false;
                }
                if(data.Status === 0) {
                    Config.notify.show('success', Config.lang("Xoa_thanh_cong"), 2000);
                }

            }
        });
    };



    const cellRenderAction = (e) => {
        if(!e) return null;
        const {data} = e.row;
        return (
            <GridActionBar>
                <div className="">
                    <IconButton aria-label="view" className={"mgr10"} size="small"
                                disabled={loading.formLoading}
                                onClick={() => onHistoryApproval(e, data)}>
                        <HistoryIcon/>
                    </IconButton>
                    <IconButton
                        aria-label="view"
                        disabled={loading.formLoading}
                        style={{ padding: 0, width: 30, height: 30, marginRight: 4 }}
                        onClick={() => onEdit(e)}
                    >
                        <Icons name={"edit"}/>
                    </IconButton>
                    <IconButton
                        aria-label="delete"
                        disabled={(data && Number(data.AppStatusID) !== 0) || loading.formLoading}
                        style={{ padding: 0, width: 30, height: 30, marginRight: 3 }}
                        onClick={() => onDelete(data)}
                    >
                        <Icons name={"delete"}/>
                    </IconButton>
                </div>
            </GridActionBar>
        );
    };
    return (
        <React.Fragment>
            <W84F3005 open={showW84F3005Popup} onClose={() => setShowW84F3005Popup(false)}
                      FormID="W09F2225" TransID={w84F3005PopupData.current.TransID}/>
            {showW09F2215Popup && <W09F2215 open={showW09F2215Popup}
                                            mode={_rowData.mode}
                                            rowData={_rowData.row}
                                            FormID="W09F2210"
                                            onClose={() => onCloseModal("W09F2215")}/>}
            <Modal
                open={open}
                maxWidth={"md"}
                fullWidth={true}
                onClose={() => onCloseModal("W09F2210")}
                title={Config.lang("Dieu_chinh_thong_tin_gia_dinh")}
            >
                <Modal.Content>
                    <FormGroup>
                        <Row>
                            <Col md={12}>
                                <TextField
                                    label={Config.lang("Thong_tin_hien_tai")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm.OldPropertyValue || ""}
                                    disabled={loading.formLoading}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        readOnly: true
                                    }}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <TextField
                                    label={Config.lang("Thong_tin_dieu_chinh")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm.PropertyValue || ""}
                                    disabled={loading.formLoading}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        readOnly: true
                                    }}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <TextField
                                    label={Config.lang("Ten_nguoi_quan_he")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm.RelativeName || ""}
                                    disabled={loading.formLoading}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => handleChange("RelativeName", e)}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Combo
                                    dataSource={dataListRelative}
                                    displayExpr={"Name"}
                                    valueExpr={"ID"}
                                    stylingMode={"underlined"}
                                    disabled={loading.formLoading}
                                    shrink={true}
                                    showClearButton={true}
                                    value={dataForm.RelationID || ""}
                                    label={Config.lang("Quan_he")}
                                    onValueChanged={(e) => handleChange("RelationID", e)}
                                    margin={"normal"}
                                />
                            </Col>
                            <Col md={6}>
                                <Combo
                                    dataSource={dataGenders}
                                    displayExpr={"Sex"}
                                    valueExpr={"ID"}
                                    stylingMode={"underlined"}
                                    disabled={loading.formLoading}
                                    shrink={true}
                                    showClearButton={true}
                                    value={dataForm.Sex || ""}
                                    label={Config.lang("Gioi_tinh")}
                                    onValueChanged={(e) => handleChange("Sex", e)}
                                    margin={"normal"}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <DateBoxPicker
                                    placeholder={"DD/MM/YYYY"}
                                    useMaskBehavior={true}
                                    showClearButton={true}
                                    width={"100%"}
                                    shrink={true}
                                    label={Config.lang("Ngay_sinh")}
                                    margin={"normal"}
                                    disabled={loading.formLoading}
                                    stylingMode={"underlined"}
                                    value={dataForm.BirthDate || ""}
                                    onValueChanged={e => handleChange("BirthDate", e)}
                                />
                            </Col>
                            <Col md={6}>
                                <TextField
                                    label={Config.lang("So_dien_thoai")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm.RelativePhone || ""}
                                    disabled={loading.formLoading}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{ min: 0 }}
                                    type={"number"}
                                    onChange={(e) => handleChange("RelativePhone", e)}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <TextField
                                    multiline={true}
                                    label={Config.lang("Ghi_chu")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={dataForm.Notes || ""}
                                    disabled={loading.formLoading}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => handleChange("Notes", e)}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={12}>
                                <Attachments
                                    isAttachInfo
                                    ref={attRef}
                                    showButton={true}
                                    files={dataOldAttachments}
                                    disabled={loading.formLoading}
                                    maxLength={5}
                                    uploading={uploading}
                                    onUploading={onUploading}
                                    onChanged={onChangeAttachments}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col sm={12}>
                                <Label className={"mgr5 " + classes.styleLabel}>{Config.lang("Lich_su_dieu_chinh")}</Label>
                                <GridContainer
                                    dataSource={dataGrid.rows}
                                    totalItems={dataGrid.total}
                                    itemPerPage={initFilter.limit}
                                    skipPerPage={initFilter.skip}
                                    typePaging={"remote"}
                                    pagerFullScreen={false}
                                    style={{ border: "none" }}
                                    showRowLines={true}
                                    showBorders={false}
                                    showColumnLines={false}
                                    noDataText={Config.lang("No_data")}
                                    hoverStateEnabled={true}
                                    loading={loading.formLoading}
                                >
                                    <Column
                                        // fixed={Config.isMobile ? false : true}
                                        caption={Config.lang("Ngay_dieu_chinh")}
                                        dataField={'TransDate'}
                                        dataType={"date"}
                                        allowEditing={false}
                                        minWidth={180}
                                    />
                                    <Column
                                        caption={Config.lang("Thong_tin_dieu_chinh")}
                                        minWidth={120}
                                        dataField={'PropertyValue'}
                                        allowEditing={false}
                                    />
                                    <Column
                                        caption={Config.lang("Nguoi_duyet")}
                                        dataField={'Approver'}
                                        allowEditing={false}
                                        minWidth={90}
                                    />
                                    <Column
                                        caption={Config.lang("Trang_thai")}
                                        dataField={'AppStatusName'}
                                        allowEditing={false}
                                        minWidth={90}
                                    />
                                    <Column
                                        alignment={"center"}
                                        fixed={true}
                                        fixedPosition={"right"}
                                        allowEditing={false}
                                        cellRender={cellRenderAction}
                                    />
                                </GridContainer>
                            </Col>
                        </Row>
                    </FormGroup>
                </Modal.Content>
                <Modal.Actions style={{ justifyContent: "space-between" }}>
                    <IconButton aria-label="view"  onClick={onAttachment}>
                        <AttachFileIcon />
                    </IconButton>
                    <ButtonGeneral
                        name={Config.lang("Luu")}
                        typeButton={"save"}
                        disabled={loading.formLoading}
                        style={{ textTransform: "uppercase" }}
                        size={"large"}
                        onClick={onSave}
                    />
                </Modal.Actions>
            </Modal>
        </React.Fragment>
    )
};

W09F2210.propTypes = {
    FormID: PropTypes.string,
    open: PropTypes.bool,
    mode: PropTypes.string,
    rowData: PropTypes.object,
    onClose: PropTypes.func,
};

export default compose(
    connect(
        (state) => ({
            dataGenders: state.W09F2210.getGenders,
            dataListRelative: state.W09F2210.getListRelative,
        }),
        (dispatch) => ({
            w09f2210Actions: bindActionCreators(W09F2210Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        })
    ),
)(W09F2210);
