import React, {useState, useEffect, useRef} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import {FormLabel as Label, Input} from "@material-ui/core";
import {Col, FormGroup, Row} from "react-bootstrap";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import Approvals from "../../../approvals/approvals";
import * as ApprovalActions from "../../../../redux/approvals/approvals_actions";
import _ from "lodash";
import {Combo} from "../../../common/form-material";
import Api from "../../../../services/api";
import Filter from "../../../filter/filter";
import DateBoxPicker from "../../../common/form-material/date-box";
import ButtonGeneral from "../../../common/button/button-general";
import LabelText from "../../../common/label-text/label-text";
import UserImage from "../../../common/user/user-image";

const W09F2220 = ({generalActions, getCboStatus, approvalActions, getGridApproval, location}) => {
    const [dataForm, setDataForm] = useState({});
    const [dataCboEmployees, setDataCboEmployees] = useState({rows: [], total: 0});
    const [dataCboProperty, setDataCboProperty] = useState([]);
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [voucherID, setVoucherID] = useState("");

    const [isLoadingForm, setLoadingForm] = useState(false);
    const [isLoadingCboEmployee, setLoadingCboEmployee] = useState(false);
    const [isLoadingCboAppStatus, setLoadingCboAppStatus] = useState(false);
    const [isLoadingCboProperty, setLoadingCboProperty] = useState(false);

    const approvalStatus = useRef(null);
    const employee = useRef(null);
    const propertyID = useRef(null);
    const approvalRef = useRef(null);

    useEffect(() => {
        Config.callChildForm({
            FormID: "W09F2220",
            // ID: voucher_id,
            keyExpr: "VoucherID",
            data:_.get(getGridApproval, "rows", []),
            onLoad: (params) => loadGrid(_.get(params, "ID", "")),
            onAction: (e, data) => {
                approvalRef.current.showPopup(data)
                loadFormInfo(_.get(e,"ID", ""));
            }
        }, {location});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    useEffect(() => {
        loadGrid();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const paramFilterCboEmployee = {
        skip: 0,
        limit: 10,
        search: ""
    };

    const handleFilterChange = (key, e) => {
        const dateField = ["dateFrom", "dateTo"];
        const value = _.get(e, "value", _.get(e, "target.value", null));
        if (_.includes(dateField, key)) {
            return key === "dateFrom" ? setDateFrom(value) : setDateTo(value);
        }
        switch (key) {
            case "approvalStatus" :
                return approvalStatus.current = value;
            case "employee" :
                return employee.current = value;
            case "propertyID" :
                return propertyID.current = value;
            default:
                return false;
        }
    };

    const loadGrid = (VoucherID = "") => {
        const params = {
            FormID: "W09F2220",
            Language: Config.language || '84',
            DivisionID: Config.getDivisionID(),
            DateFrom: dateFrom,
            DateTo: dateTo,
            ApprovalStatus: approvalStatus.current,
            EmployeeID: _.get(employee.current, "EmployeeID", ""),
            TransTypeID: propertyID.current,
            VoucherID
        };
        approvalActions.getGridApprovals(params, (err) => {
            if (err) return Config.popup.show("ERROR", err);

        });
    };

    const loadFormInfo = VoucherID => {
        const params = {
            FormID: "W09F2220",
            Language: Config.language || "84",
            Type: 0,
            VoucherID
        };
        setLoadingForm(true)
        approvalActions.getFormInfo(params, (err, data) => {
            setLoadingForm(false)
            if (err) return Config.popup.show("ERROR", err);
            if (data) setDataForm(data);
        });
    };

    const loadCboEmployees = (isReset) => {
        const param = {
            Type: "EmployeeID",
            FormID: "W09F2220",
            Language: Config.language || "84",
            ...paramFilterCboEmployee,
        };
        setLoadingCboEmployee(true);
        generalActions.getCboEmployees(param, (err, data) => {
            setLoadingCboEmployee(false);
            if (err) return Config.popup.show("ERROR", err);
            if (data) {
                const rows = _.get(data, "rows", false) || data;
                const total = _.get(data, "total", 0) || _.size(data);
                setDataCboEmployees({rows: isReset ? rows : _.concat(dataCboEmployees.rows, rows), total})
            }
        });
    };

    const loadCboProperty = async () => {
        setLoadingCboProperty(true)
        const res = await Api.put("/w09f2002/get-property", {Language: Config.language || "84"})
        setLoadingCboProperty(false)
        const resData = _.get(res, "data", {});
        const resMessageErr = _.get(res, "message", _.get(resData, "Message", ""));
        if (resMessageErr) return Config.popup.show("ERROR", resMessageErr);
        if (resData) setDataCboProperty(resData)

    };

    const loadCboAppStatus = () => {
        const params = {
            FormID: "STANDARD",
            Language: Config.language || '84'
        };
        setLoadingCboAppStatus(true)
        approvalActions.getCboStatus(params, (err) => {
            setLoadingCboAppStatus(false)
            if (err) return Config.popup.show("ERROR", err);
        });
    };

    const onSelectionChanged = (e) => {
        loadFormInfo(_.get(e, "VoucherID", ""));
    };

    const onLoadDataFilter = () => {
        loadCboEmployees();
        loadCboAppStatus();
        loadCboProperty();
    };

    const onDrawerClosed = () => {
        setVoucherID("");
    };

    const onSearch = () => {
        loadGrid();
    };

    const renderFormInfo = (data) => {
        return <>
            <FormGroup>
                <Row>
                    <Col xs={12}>
                        <LabelText
                            allowPadding={true}
                            label={Config.lang("Nhan_vien")}
                            value={() => {
                                return (
                                    <div className={"display_row align-center pdb5"}
                                         style={{
                                             width: "100%",
                                             height: 37
                                         }}>
                                        <UserImage data={data} allowHover={false} width={32} height={32}/>
                                        <Input
                                            color={"primary"}
                                            readOnly={true}
                                            value={Config.helpers.getObjectValue(data, "EmployeeName", "")}
                                            disableUnderline={true}
                                        />
                                    </div>
                                );
                            }}
                            fullWidth={true}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={12}>
                        <LabelText
                            label={Config.lang("Thong_tin_cu")}
                            value={_.get(data, 'OldPropertyValue', "")}
                            fullWidth={true}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={12}>
                        <LabelText
                            label={Config.lang("Thong_tin_dieu_chinh")}
                            value={_.get(data, 'PropertyValue', "")}
                            fullWidth={true}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={12}>
                        <LabelText
                            label={Config.lang("Ghi_chu")}
                            value={_.get(data, 'Notes', "")}
                            fullWidth={true}
                        />
                    </Col>
                </Row>
            </FormGroup>
        </>
    };

    const renderFilter = () => {
        return <Filter
            onClosed={() => {
                console.log("closed")
            }}
            showHeader={false}
            placeholder={Config.lang("Noi_dung_can_tim_kiem")}
            onOpenLoaded={onLoadDataFilter}
            renderFilter={() =>
                <>
                    <FormGroup style={{marginBottom: "7px"}}>
                        <Row>
                            <Col xs={12}>
                                <Combo
                                    loading={isLoadingCboAppStatus}
                                    label={Config.lang("Trang_thai_duyet")}
                                    dataSource={getCboStatus}
                                    displayExpr={"AppStatusName"}
                                    valueExpr={"ApprovalStatus"}
                                    onValueChanged={(e) => handleFilterChange("approvalStatus", e)}
                                    showClearButton={true}
                                    value={approvalStatus.current}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup style={{marginBottom: "7px"}}>
                        <Row>
                            <Col xs={12}>
                                <Combo.LoadMore
                                    dataSource={dataCboEmployees.rows}
                                    skip={paramFilterCboEmployee.skip}
                                    limit={paramFilterCboEmployee.limit}
                                    displayExpr={"EmployeeName"}
                                    keyExpr={'EmployeeID'}
                                    valueExpr={'EmployeeID'}
                                    value={employee.current ? employee.current : null}
                                    loading={isLoadingCboEmployee}
                                    stylingMode={'outlined'}
                                    label={Config.lang('Nhan_vien')}
                                    itemRender={(e) => {
                                        const {data} = e;
                                        if (!data) return null;
                                        return data.EmployeeID + ' - ' + data.EmployeeName;
                                    }}
                                    showClearButton={true}
                                    onValueChanged={e => handleFilterChange("employee", e)}
                                    onInputChanged={(e) => {
                                        paramFilterCboEmployee.search = e.target.value;
                                        paramFilterCboEmployee.skip = 0;
                                        loadCboEmployees(true);
                                    }}
                                    onLoadMore={(e) => {
                                        paramFilterCboEmployee.skip = e.skip;
                                        paramFilterCboEmployee.limit = e.limit;
                                        loadCboEmployees();
                                    }}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup style={{marginBottom: "7px"}}>
                        <Row>
                            <Col xs={12}>
                                <Combo
                                    label={Config.lang("Dieu_chinh")}
                                    dataSource={dataCboProperty}
                                    displayExpr={"PropertyName"}
                                    valueExpr={"PropertyID"}
                                    onValueChanged={(e) => handleFilterChange("propertyID", e)}
                                    showClearButton={true}
                                    loading={isLoadingCboProperty}
                                    value={propertyID.current}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col xs={12}>
                                <Label className={"mgr5"}>{Config.lang("Ngay_dieu_chinh")}</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6}>
                                <div className={"display_row align-center"}>
                                    <Label className={"mgr5"}>{Config.lang("Tu")}</Label>
                                    <DateBoxPicker
                                        placeholder={"dd/mm/yyyy"}
                                        max={dateTo}
                                        useMaskBehavior={true}
                                        showClearButton={true}
                                        width={"100%"}
                                        value={dateFrom}
                                        onValueChanged={e => handleFilterChange("dateFrom", e)}
                                    />
                                </div>
                            </Col>
                            <Col xs={12} sm={6}>
                                <div className={"display_row align-center"}>
                                    <Label className={"mgr5"}>{Config.lang("Den")}</Label>
                                    <DateBoxPicker
                                        min={dateFrom}
                                        placeholder={"dd/mm/yyyy"}
                                        useMaskBehavior={true}
                                        showClearButton={true}
                                        width={"100%"}
                                        value={dateTo}
                                        onValueChanged={e => handleFilterChange("dateTo", e)}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup className={"display_row align-center valign-middle"}>
                        <ButtonGeneral
                            name={Config.lang("Tim_kiem")}
                            typeButton={"search"}
                            size={"large"}
                            color={"primary"}
                            variant={"outlined"}
                            onClick={onSearch}
                        />
                    </FormGroup>
                </>}
        />
    };

    return <>
        <FormGroup>
            <ActionToolbar title={Config.lang("Duyet_dieu_chinh_thong_tin")}/>
        </FormGroup>
        <FormGroup>
            <Row>
                <Col xs={12}>
                    <Approvals
                        ref={(ref) => {
                            approvalRef.current = ref
                        }}
                        loading={isLoadingForm}
                        allowHistoryApproval={true}
                        FormID={"W09F2220"}
                        dataForm={dataForm}
                        dataSource={getGridApproval}
                        selectedRowKey={voucherID}
                        disabledApproval={false}
                        singleClick
                        formInfoRender={renderFormInfo}
                        onSelectionChanged={onSelectionChanged}
                        onDrawerClosed={onDrawerClosed}
                        filterRender={renderFilter}
                        disableEscapeKeyDown={false}
                    />
                </Col>
            </Row>
        </FormGroup>
    </>
}

export default connect((state) => ({
        getCboStatus: state.approvals.getCboStatus,
        getGridApproval: state.approvals.getGridApproval,
    }),
    (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        approvalActions: bindActionCreators(ApprovalActions, dispatch),
    })
)(W09F2220)

