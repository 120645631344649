/**
 * @Copyright 2021 @DigiNet
 * @Author QUOCUY
 * @Create 6/24/2021
 * @Example
 */

import React, { useEffect, useRef, useState } from "react";
import {
    Button,
    Typography,
    Radio,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    CircularProgress,
} from "diginet-core-ui/components";
import { useDispatch } from "react-redux";
import * as W09F2230Actions from "../../../../redux/W0X/W09F2230/W09F2230_actions";
import Config from "../../../../config";

const styles = {
    loadingContainer: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        zIndex: 9999,
    },
};

const W09F2230 = props => {
    const { open, onClose } = props;
    const [loading, setLoading] = useState(false);
    const [isDefaultMSS, setIsDefaultMSS] = useState();

    const dispatch = useDispatch();

    const dataOld = useRef();

    const loadData = () => {
        setLoading(true);
        dispatch(
            W09F2230Actions.getForm((errors, data) => {
                if (errors) {
                    const message = errors.message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    return false;
                } else if (data) {
                    dataOld.current = data?.IsDefaultMSS;
                    setIsDefaultMSS(data?.IsDefaultMSS);
                }
                setLoading(false);
            })
        );
    };

    useEffect(() => {
        if (open) loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const onSave = () => {
        const params = {
            IsDefaultMSS: isDefaultMSS,
        };
        setLoading(true);
        dispatch(
            W09F2230Actions.edit(params, (errors, data) => {
                if (errors) {
                    const message = errors.message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    setLoading(false);
                    return false;
                } else if (data) {
                    if (data.Status === 1) {
                        let message = data.Message || Config.lang("Loi_chua_xac_dinh");
                        Config.popup.show("INFO", message);
                        setLoading(false);
                    } else {
                        dataOld.current = isDefaultMSS;
                        setLoading(false);
                        Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                    }
                }
            })
        );
    };

    return (
        <Modal
            onClose={onClose}
            open={open}
            pressESCToClose={false}
            moveable={false}
            dragAnyWhere={false}
            moveOutScreen={false}
            width={"50%"}
        >
            <ModalHeader title={Config.lang("Cai_dat")} />
            <ModalBody>
                {loading && (
                    <div style={styles.loadingContainer}>
                        <CircularProgress size={"sm"} />
                    </div>
                )}
                <Typography className="mgb5">{Config.lang("Man_hinh_mac_dinh_khi_dang_nhap")}</Typography>
                <Radio
                    className="mgb5"
                    name={"option"}
                    label={"ESS"}
                    value={"ESS"}
                    checked={isDefaultMSS === 0}
                    onChange={() => setIsDefaultMSS(0)}
                />
                <Radio
                    className="mgb5"
                    name={"option"}
                    label={"MSS"}
                    value={"MSS"}
                    checked={isDefaultMSS === 1}
                    onChange={() => setIsDefaultMSS(1)}
                />
            </ModalBody>
            <ModalFooter>
                <Button
                    disabled={loading || isDefaultMSS === dataOld.current}
                    height={40}
                    viewType={"filled"}
                    color={"info"}
                    text={Config.lang("Luu")}
                    onClick={onSave}
                />
            </ModalFooter>
        </Modal>
    );
};
export default W09F2230;
