/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 3/4/2021
 * @Example 
 */

import React, {useEffect, useState, useRef} from "react";
import {bindActionCreators, compose} from "redux";
import {makeStyles, IconButton, Divider} from "@material-ui/core";
import {connect} from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import ButtonGeneral from "../../../common/button/button-general";
import GridContainer from "../../../grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";
import {Image, FormGroup, Row, Col} from "react-bootstrap";
import Filter from "../../../filter/filter";
import {Combo} from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import moment from "moment";
import {browserHistory} from "react-router";

import * as W09F2225Actions from "../../../../redux/W0X/W09F2225/W09F2225_actions";
import UserImage            from "../../../common/user/user-image";
import UserName             from "../../../common/user/user-name";
import Status               from "../../../common/status/status";
import Icons                from "../../../common/icons/";
import W84F3005             from "../../../W8X/W84/W84F3005/W84F3005";
import HistoryIcon          from "@material-ui/icons/History"
import W09F2205             from "../W09F2205/W09F2205";
import W09F2215             from "../W09F2215/W09F2215";
import DOMPurify from "dompurify";

const useStyles = makeStyles(theme => ({
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: "50%",
        overflow: "hidden",
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain"
    },
    chip: {
        fontSize: "1rem",
        fontWeight: 400,
        border: "none",
        backgroundColor: "#EBEBEB"
    },
    dateInfo: {
        display: "flex",
        alignItems: "center"
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: "#575757"
    },
    divDateText: {
        fontSize: "14px",
        fontWeight: 400,
        color: theme.palette.text.subtext
    }
})) ;

const W09F2225 = (props) => {
    const _initFilter = {
        AppStatusID: null,
        PropertyID: "",
        skip: 0,
        limit: 10
    };

    const _dataDate = {
        DateFrom: null,
        DateTo: null,
    };

    const _initDataGrid = {
        rows: [],
        total: 0
    };

    const _w84F3005PopupData = {
        TransID: null
    };

    const _initRowData = {
        row: {},
        mode: ""
    };

    const {getCboAppStatus, getDataCboProperty} = props;
    const [iPermission, setPermission] = useState(0);
    const [dataGrid, setDataGrid] = useState(_initDataGrid);
    const [dataDate, _setDataDate] = useState(_dataDate);
    const [loading, _setLoading] = useState({gridLoading: false});
    const [showW84F3005Popup, setShowW84F3005Popup] = useState(false);
    const [showW09F2205Popup, setShowW09F2205Popup] = useState(false);
    const [showW09F2215Popup, setShowW09F2215Popup] = useState(false);
    const [rowData, setRowData] = useState(_initRowData);
    const dataFilter = useRef(_initFilter);
    const w84F3005PopupData = useRef(_w84F3005PopupData);
    const classes = useStyles();

    const setLoading = (obj) => {
        _setLoading({...loading,...obj});
    };

    const setDataDate = (obj) => {
        _setDataDate({...dataDate,...obj});
    };

    const onSearch = () => {
        loadDataGrid();
    };

    const onChangePageProfile = data => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: {EmployeeID: data.EmployeeID}
        });
    };

    const loadDataGrid = (VoucherID) => {
        const {voucher_id} = Config.getUrlParams(props);
        VoucherID = VoucherID ? VoucherID : voucher_id;
        const param = {
            FormID: "W09F2225",
            Language: Config.language || 84,
            EmployeeID: Config.profile.UserID || "",
            DateFrom: dataDate.DateFrom || null,
            DateTo: dataDate.DateTo || null,
            ApprovalStatus: dataFilter.current.AppStatusID ?? null,
            PropertyID: dataFilter.current.PropertyID || "",
            Skip: dataFilter.current.skip,
            Limit: dataFilter.current.limit,
            VoucherID: VoucherID || ""
        };
        setLoading({gridLoading: true});
        props.w09f2225Actions.getDataGrid(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            setLoading({gridLoading: false});
            if(data) {
                setDataGrid({
                    rows: data.rows,
                    total: data.total
                })
            }
        });
    };

    const loadCboAppStatus = () => {
        const param = {
            FormID: "W09F2225",
            Language: Config.language || 84
        };

        props.generalActions.getCboAppStatus(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    const loadDataCboProperty = () => {
        const param = {
            Language: Config.language || 84
        };

        props.w09f2225Actions.getDataCboProperty(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    const onChangePage = page => {
        dataFilter.current.skip = page * dataFilter.current.limit;
        loadDataGrid();
    };

    const onChangePerPage = perPage => {
        dataFilter.current.skip = 0;
        dataFilter.current.limit = perPage;
        loadDataGrid();
    };

    const onCloseModal = (isLoaded = false) => {
        if(isLoaded) {
            loadDataGrid();
        }
    };

    useEffect(() => {
        const loadPermission = async () => {
            await props.generalActions.getPermission("W09F2225", (isPer) => {
                setPermission(isPer);
            });
        };
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const {voucher_id} = Config.getUrlParams(props);
        if(iPermission) {
            loadDataGrid(voucher_id);
            loadCboAppStatus();
            loadDataCboProperty();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [iPermission]);

    useEffect(() => {
        // const {getFormGrid} = props;
        // Config.callChildForm({
        //     FormID: "W09F2225",
        //     keyExpr: "TransID",
        //     data: getFormGrid.rows || [],
        //     onLoad: (params) => loadDataGrid(params && params.ID),
        //     onAction: (e, data) => redirectScreen(1, {data: data})
        // }, props);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    });

    const renderEmpProfile = e => {
        const {data} = e.row;
        const date = moment.utc(data.CreateDate).isValid() ?
            moment.utc(data.CreateDate).format("LT, DD/MM/YYYY") : "";
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={data} onClick={() => onChangePageProfile(data)}/>
                <div className={"w75f2000_info"}>
                    <div className={classes.divText}>
                        <UserName data={data}/>
                    </div>
                    <div className={classes.divText}>
                        {data.DepartmentName || ""}
                    </div>
                    <div className={classes.dateInfo}>
                        <Image
                            src={require("../../../../assets/images/icon-calendar.svg")}
                        />
                        <div style={{paddingLeft: "8px"}} className={"date-text-info"}>
                            {data.CreateDate && date}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderInfo = e => {
        const {data} = e.row;
        const description = data?.VoucherNo ? <div
            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.VoucherNo)}} /> : null;
        return (
            <div className={"pdt10 pdb10 " + classes.divText}>
                {description}
            </div>
        );
    };

    const renderStatus = e => {
        const {data} = e.row;
        return (
            <Status data={data} />
        );
    };

    const onEdit = e => {
        const {data} = e.row;
        setRowData({row: data, mode: "edit"});
        if(data.PropertyID === "RELATIVE") {
            setShowW09F2215Popup(true);
        }
        if(data.PropertyID !== "RELATIVE") {
            setShowW09F2205Popup(true);
        }
    };

    const onHistoryApproval = (e, data = {}) => {
        w84F3005PopupData.current.TransID = data.TransID;
        setShowW84F3005Popup(true);
    };

    const onAdd = () => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: { EmployeeID: Config.profile.HREmployeeID ,mode: "add", FormID: "W09F2225"}
        });
    };

    const onDelete = data => {
        const {TransID } = data;
        const param = {
            TransID: TransID
        };
        Config.popup.show(
            "YES_NO",
            Config.lang("Ban_co_chac_muon_xoa?"),
            () => {
                props.w09f2225Actions.deleteData(param, error => {
                    if (error) {
                        Config.popup.show("ERROR", error);
                        return false;
                    } else {
                        Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 3000);
                        loadDataGrid();
                    }
                });
            }
        );
    };

    const renderAction = e => {
        const {data} = e.row;
        return (
            <div className={"display_row align-center"}>
                <IconButton aria-label="view" className={"mgr10"} size="small"
                            disabled={!(iPermission > 0)}
                            onClick={() => onHistoryApproval(e, data)}>
                    <HistoryIcon/>
                </IconButton>
                <IconButton
                    aria-label="edit"
                    size="small"
                    disabled={!(iPermission > 2)}
                    onClick={() => onEdit(e)}
                >
                    <Icons name={"view"}/>
                </IconButton>
                <Divider
                    orientation="vertical"
                    style={{width: 2, height: 11, margin: "0 5px"}}
                />
                <IconButton
                    aria-label="delete"
                    size="small"
                    disabled={!(iPermission > 3) || (data && Number(data.AppStatusID) !== 0)}
                    onClick={() => onDelete(data)}
                >
                    <Icons name={"delete"}/>
                </IconButton>
            </div>
        );
    };

    const filterChange = (key, e) => {
        if (!key) return false;
        switch (key) {
            case "DateFrom":
                setDataDate({DateFrom: e.value});
                break;
            case "DateTo":
                setDataDate({DateTo: e.value});
                break;
            case "AppStatusID":
                dataFilter.current.AppStatusID = e.value;
                break;
            case "PropertyID":

                dataFilter.current.PropertyID =  e.value;
                break;
            default:
                break;
        }
    };

    const renderFilter = () => {
        return (
            <Filter
                placeholder={Config.lang("Nhap_tu_tim_kiem")}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup style={{marginBottom: "7px"}}>
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <div className={classes.divText}>
                                                {Config.lang("Tu")}
                                            </div>
                                            <DateBoxPicker
                                                dateBoxProps={{max: dataDate.DateTo}}
                                                style={{marginLeft: "5px"}}
                                                onValueChanged={e => filterChange("DateFrom", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={dataDate.DateFrom}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div style={{display: "flex", alignItems: "center"}}>
                                            <div className={classes.divText}>
                                                {Config.lang("Den").toLowerCase()}
                                            </div>
                                            <DateBoxPicker
                                                dateBoxProps={{min: dataDate.DateFrom}}
                                                style={{marginLeft: "5px"}}
                                                onValueChanged={e => filterChange("DateTo", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={dataDate.DateTo}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{marginBottom: "7px"}}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            label={Config.lang("Trang_thai_duyet")}
                                            dataSource={getCboAppStatus}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"AppStatusID"}
                                            onValueChanged={e => filterChange("AppStatusID", e)}
                                            showClearButton={true}
                                            value={dataFilter.current.AppStatusID || ""}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup style={{marginBottom: "7px"}}>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Combo
                                            label={Config.lang("Truong_dieu_chinh")}
                                            dataSource={getDataCboProperty}
                                            displayExpr={"PropertyName"}
                                            valueExpr={"PropertyID"}
                                            onValueChanged={e => filterChange("PropertyID", e)}
                                            showClearButton={true}
                                            value={dataFilter.current.PropertyID || ""}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <ButtonGeneral
                                    name={Config.lang("Tim_kiem")}
                                    typeButton={"search"}
                                    size={"large"}
                                    color={"primary"}
                                    variant={"outlined"}
                                    onClick={onSearch}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    return (
        <>
            <W84F3005 open={showW84F3005Popup} onClose={() => setShowW84F3005Popup(false)}
                      FormID="W09F2225" TransID={w84F3005PopupData.current.TransID}/>
            {showW09F2205Popup && <W09F2205
                                            open={showW09F2205Popup}
                                            mode={rowData.mode}
                                            rowData={rowData.row}
                                            FormID="W09F2225"
                                            onClose={(isLoaded) => {
                                                onCloseModal(isLoaded);
                                                setShowW09F2205Popup(false)
                                            }}/>}
            {showW09F2215Popup && <W09F2215 open={showW09F2215Popup}
                                            mode={rowData.mode}
                                            rowData={rowData.row}
                                            FormID="W09F2225"
                                            onClose={(isLoaded) => {
                                                onCloseModal(isLoaded);
                                                setShowW09F2215Popup(false)}
                                            }/>}
            <div className={"hidden"}>{renderFilter()}</div>
            <ActionToolbar title={Config.lang("Dieu_chinh_thong_tin")}>
                <ButtonGeneral
                    name={Config.lang("Them")}
                    typeButton={"add"}
                    style={{textTransform: "uppercase"}}
                    disabled={!(iPermission > 3)}
                    size={"large"}
                    onClick={onAdd}
                />
            </ActionToolbar>
            <GridContainer
                style={{border: "none"}}
                dataSource={dataGrid.rows}
                keyExpr={"TransID"}
                showRowLines={true}
                showBorders={false}
                showColumnLines={false}
                showColumnHeaders={false}
                noDataText={Config.lang("No_data")}
                loading={loading.gridLoading}
                hoverStateEnabled={true}
                totalItems={(dataGrid && Number(dataGrid.total)) || 0}
                itemPerPage={dataFilter.current.limit}
                skipPerPage={dataFilter.current.skip}
                typePaging={"remote"}
                height={Config.getHeightGrid()}
                onChangePage={onChangePage}
                onChangePerPage={onChangePerPage}
            >
                <Column cellRender={renderEmpProfile} width={250}/>
                <Column cellRender={renderInfo}/>
                <Column cellRender={renderStatus} alignment={"center"} width={200}/>
                <Column cellRender={renderAction} width={200}/>
            </GridContainer>
        </>
    );


};

export default compose(
    connect(
        state => ({
            getCboAppStatus: state.general.getCboAppStatus,
            getDataCboProperty: state.W09F2225.getDataCboProperty
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w09f2225Actions: bindActionCreators(W09F2225Actions, dispatch)
        })
    ),
)(W09F2225);
