import React, {useState, useEffect, useRef} from "react";
import Config from "../../../../config";
import {
    TextInput,
    Button,
    Label,
    Avatar,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    ButtonIcon,
    HelperText, DatePicker
} from "diginet-core-ui/components";
import Modal from "../../../common/modal/modal";
import {Col, Row, FormGroup} from "react-bootstrap";
import GridContainer from "../../../grid-container/grid-container";
import HeadClick from "../../../grid-container/head-click";
import {Column, CustomRule, Summary, TotalItem} from "devextreme-react/data-grid";
import Attachments from "../../../common/attachments/attachments";
import Api from '../../../../services/api';
import _ from "lodash";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import * as W09F2070Actions from "../../../../redux/W0X/W09F2070/W09F2070_actions";
import W09F6000 from "../W09F6000/W09F6000";
import * as generalActions from "../../../../redux/general/general_actions";
import withStyles from "@material-ui/core/styles/withStyles";
import History from "../../../libs/history";
import moment from "moment";


const styles = {
    bgRow: {
        "& td": {
            opacity: 0.6
        }
    },
    bgRowDiff: {
        "& td": {
            backgroundColor: "lightblue"
        }
    },
    bgRowDiff2: {
        "& td": {
            backgroundColor: "#6495ED"
        }
    },
    bgRowDiff3: {
        "& td": {
            backgroundColor: "#FAEBD7"
        }
    },
    bgRowDiff4: {
        "& td": {
            backgroundColor: "#F0F8FF"
        }
    },
    hidden: {
        height: 0,
        overflow: "hidden",
        display: "none"
    },
}
const W09F2271 = ({
                      data,
                      open,
                      onClose,
                      w09f2070Actions,
                      generalActions,
                      getCboDisRewardForm,
                      getCboProDisRewardLevel,
                      getCboDisViolationActGroup,
                      getRewardCaption,
                      classes
                  }) => {
    const [dataForm, setDataForm] = useState({
        VoucherID: "",
        VoucherName: "",
        VoucherCreateDate: moment(),
        ViolationDate: moment(),
        Notes: ""
    });
    const [isLoadingForm, setLoadingForm] = useState(false);
    const [errors, setErrors] = useState({});

    const [dataGrid, setDataGrid] = useState([]);
    const [isLoadingGrid, setLoadingGrid] = useState(false);

    const [cboDisViolationAct, setCboDisViolationAct] = useState({});

    const [dataOldAttachments, setDataOldAttachments] = useState([]);
    const [isLoadingAttachment, setLoadingAttachment] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [isSaving, setSaving] = useState(false);

    const [filterGridValue, setFilterGridValue] = useState([]);
    const [isShowModalW09F6000, setShowModalW09F6000] = useState(false);

    let cboDisViolationActTmp = useRef({});
    let attachments = useRef([]);
    let deletedFile = useRef([]);
    let selectedRange = useRef(null);
    let editing = useRef(0);
    let filterColIndx = useRef(null);
    let refDataGrid = useRef(null);
    const attRef = useRef(null);
    let filterGrid = useRef({});
    let oldDataGrid = useRef([]);
    let oldDataForm = useRef({});
    let timerUpdating = useRef("");
    let cellEditing = useRef({rowIndex: -1, dataField: ""});
    let fieldUpdating = useRef({dataField: "", value: ""});
    const {mode, rowData} = data;

    useEffect(() => {
        let loadingTimer = "";
        (async () => {
            if (mode === "add") return;
            setLoadingForm(true)
            const params = {
                VoucherID: _.get(rowData, "VoucherID", "") || ""
            }
            const res = await Api.put("/w09f2271/load-master", params);
            const getData = _.get(res, "data", {});
            const getMessageErr = _.get(res, "message", _.get(getData, "Message", ""));
            loadingTimer = setTimeout(() => {
                setLoadingForm(false);
            }, 300);
            if (getMessageErr) return Config.popup.show("ERROR", getMessageErr);
            const dataForm = {
                VoucherID: _.get(getData, "VoucherID", ""),
                VoucherName: _.get(getData, "VoucherName", ""),
                VoucherCreateDate: _.get(getData, "VoucherCreateDate", null),
                ViolationDate: _.get(getData, "ViolationDate", null),
                Notes: _.get(getData, "Notes", "")
            }
            if (mode === "edit") oldDataForm.current = dataForm;
            setDataForm(dataForm);
        })()
        return () => {
            if (loadingTimer) clearTimeout(loadingTimer)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let loadingTimer = "";
        (async () => {
            if (mode === "add") return;
            setLoadingGrid(true);
            const params = {
                VoucherID: _.get(rowData, "VoucherID", "") || ""
            }
            const res = await Api.put("/w09f2271/load-gird", params);
            const getData = _.get(res, "data", {});
            const getMessageErr = _.get(res, "message", _.get(getData, "Message", ""))
            loadingTimer = setTimeout(() => {
                setLoadingGrid(false);
            }, 300);
            if (getMessageErr) return Config.popup.show("ERROR", getMessageErr);
            if (mode === "edit") oldDataGrid.current = getData;
            setDataGrid(_.map(getData, item => ({...item, IsCheck: !!item?.IsCheck})));
            _.uniqBy(getData, "DisViolationActGroupID")
                .forEach(item => {
                    item.DisViolationActGroupID && loadCboDisViolationAct(item.DisViolationActGroupID, data => {
                        setCboDisViolationAct(data)
                    })
                })
        })()
        return () => {
            if (loadingTimer) clearTimeout(loadingTimer)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        w09f2070Actions.loadCboDisViolationActGroup(error => {
            if (error) Config.popup.show("ERROR", error);
        })
        w09f2070Actions.loadRewardCaption({Language: Config.language || "84"}, (error) => {
            if (error) Config.popup.show("ERROR", error);
        });
        w09f2070Actions.loadCboProDisRewardLevel(error => {
            if (error) Config.popup.show("ERROR", error);
        });
        w09f2070Actions.loadCboDisRewardForm(error => {
            if (error) Config.popup.show("ERROR", error);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (mode !== "add") {
            const param = {
                KeyID: _.get(rowData, "VoucherID", "") || "",
                TableName: "D09T2060"
            };
            setLoadingAttachment(true);
            generalActions.getAttachmentsByTransID(param, (error, data) => {
                setLoadingAttachment(false);
                if (error) {
                    Config.popup.show('ERROR', error);
                    return false;
                }
                if (data && data.length > 0) {
                    setDataOldAttachments(data);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validation = (field, value, type = "required") => {
        let objError = {...errors};
        let isError = false;
        switch (type) {
            case "required":
                if (!value) {
                    isError = true;
                    objError = {...objError, [field]: Config.lang("Truong_nay_bat_buoc_nhap")};
                } else {
                    objError = _.has(objError, field) ? _.omit(objError, field) : objError;
                }
                break;
            default:
                break
        }
        setErrors(objError);
        return isError
    }

    const loadCboDisViolationAct = async (DisViolationActGroupID = "", cb) => {
        const res = await Api.put("/w09f2070/load-cbo-disviolation-act", {DisViolationActGroupID});
        const getData = _.get(res, "data", {});
        const getMessageErr = _.get(res, "message", _.get(getData, "Message", ""))
        if (getMessageErr) return Config.popup.show('ERROR', getMessageErr);
        cboDisViolationActTmp.current = {...cboDisViolationActTmp.current, [DisViolationActGroupID]: getData};
        if (cb) cb(cboDisViolationActTmp.current);
    }

    const getFilterGrid = (isClear) => {
        if (isClear) filterGrid = {};
        const filter = filterGrid.current;
        const dataFilter = [];
        Object.keys(filter).forEach(fil => {
            const itemFilter = filter[fil];
            if (itemFilter && itemFilter.length > 0) {
                dataFilter.push(filter[fil]);
                dataFilter.push("and");
            }
        });
        dataFilter.pop();
        return dataFilter;
    };

    const _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(attachments.current);
        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    const onRowPrepared = (e) => {
        if (!e) return false;
        const {data} = e;
        if (data?.Updated === 1 || data?.AddEmp === 0) {
            e.rowElement.classList.add(classes.bgRowDiff);
        }
        if (data?.Updated === 0 || data?.AddEmp === 1) {
            e.rowElement.classList.add(classes.bgRowDiff2);
        }
        if (data?.Updated === 1 || data?.AddEmp === 1) {
            e.rowElement.classList.add(classes.bgRowDiff3);
        }
        if (data?.Updated === 0 || data?.AddEmp === 0) {
            e.rowElement.classList.add(classes.bgRowDiff4);
        }
        if (data?.IsCancel === 2) {
            e.rowElement.classList.add(classes.bgRow);
        }
        if (data?.Deleted === 1) {
            e.rowElement.classList.add(classes.hidden);
        }
    };

    const onSelectedEmployees = async () => {
        setShowModalW09F6000(false);
        setLoadingGrid(true);
        const res = await Api.put("/w09f2271/load-employee");
        setLoadingGrid(false);
        const getData = _.get(res, "data", []);
        const getMessageErr = _.get(res, "message", _.get(getData, "Message", ""))
        if (getMessageErr) return Config.popup.show("ERROR", getMessageErr);
        setErrors(_.omit(errors, "dataGrid"));
        setDataGrid(_.uniqBy([...dataGrid, ...getData], "EmployeeID"));
    }

    const onChangeForm = (key, e) => {
        const value = _.get(e, "target.value", _.get(e, "value", ""));
        if (_.includes(["VoucherName", "ViolationDate"], key)) validation(key, value);
        setDataForm({...dataForm, [key]: value});
    };

    const onChangeAttachments = (e) => {
        attachments.current = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            deletedFile.current = [...e.deletedFiles];
            const _arrRemove = deletedFile.map((d) => d.AttachmentID);
            const dataNew = [...dataOldAttachments].filter(att => _arrRemove.indexOf(att.AttachmentID) < 0);
            setDataOldAttachments(dataNew);
        }
    };

    const onFilterGrid = (e) => {
        let {column, filterValue, columnIndex} = e;
        filterColIndx.current = columnIndex;
        e.component.saveEditData();
        if (column) {
            if (column.dataField === "EmployeeID") {
                let dataFilter = [];
                if (filterValue) {
                    if (filterValue.indexOf(",") > -1) {
                        const arrFilters = filterValue.split(",");
                        arrFilters.forEach(f => {
                            dataFilter.push(["EmployeeID", "=", f.trim()]);
                            dataFilter.push("or");
                        });
                        dataFilter.pop();
                    } else {
                        dataFilter = ["EmployeeID", "contains", filterValue];
                    }
                }
                filterGrid.current.EmployeeID = dataFilter;
            } else {
                let operator = "contains";
                if (["ValidDate", "ValidDateEnd", "IsCheck", "NumMonth", "DisViolationActGroupID", "DisViolationActID", "ProDisRewardLevelID", "ProDisRewardFormID"].includes(column.dataField)) operator = "=";
                filterGrid.current[column.dataField] = (filterValue || typeof filterValue === "boolean") ? [column.dataField, operator, filterValue] : [];
            }
            setFilterGridValue(getFilterGrid());
        } else {
            filterGrid.current = {};
            setFilterGridValue([]);
        }
    };

    const onEditorPreparing = e => {
        if ((e.parentType === "searchPanel" || e.parentType === "filterRow") && e.dataField === "EmployeeID") {
            e.updateValueTimeout = 2500;
        }
        if (e.parentType === "dataRow") {
            switch (e.dataField) {
                case "DisViolationActID":
                    e.editorOptions.dataSource = cboDisViolationAct[e.row.data.DisViolationActGroupID]
                    break;
                case "ValidDate" :
                    e.editorOptions.max = e.row.data.ValidEndDate;
                    break;
                case "ValidEndDate":
                    e.editorOptions.min = e.row.data.ValidDate;
                    break;
                default:
                    break;
            }
        }
    };

    const handleUpdateCell = (field, data1, data2) => {
        let newValue = "";
        switch (field) {
            case "NumMonth": {
                newValue = _.round(moment(data1).diff(moment(data2), "months", true));
                if (!_.isNumber(newValue)) newValue = 0;
                break;
            }
            case "ValidDate": {
                newValue = moment(data1)
                    .subtract(Number(data2), "months")
                    .format("YYYY-MM-DD");
                if (newValue === "Invalid date") newValue = null;
                break;
            }
            case "ValidEndDate": {
                newValue = moment(data1)
                    .add(Number(data2), "month")
                    .format("YYYY-MM-DD");
                if (newValue === "Invalid date") newValue = null;
                break;
            }
            default:
                break
        }
        return {dataField: field, value: newValue}
    }

    const onEditingStart = (e) => {
        const {data, column, key} = e;
        if (data.IsUpdate === 1) {
            e.cancel = true;
        }
        const getRowIndex = _.findIndex(dataGrid, o => o.EmployeeID === key);
        cellEditing.current = {rowIndex: getRowIndex, dataField: column.dataField};
    };

    const onRowUpdated = e => {
        const {data} = e;
        const {rowIndex} = cellEditing.current;
        const {dataField, value} = fieldUpdating.current;
        data.Updated = 1;
        if (timerUpdating.current) clearTimeout(timerUpdating.current);
        if (_.isEmpty(fieldUpdating.current)) return false;
        if (rowIndex === -1) return false;
        timerUpdating.current = setTimeout(() => {
            e.component.cellValue(rowIndex, dataField, value);
            fieldUpdating.current = {dataField: "", value: ""};
            cellEditing.current = {rowIndex: -1, dataField: ""};
        }, 300);
    }

    const onRowUpdating = e => {
        const {newData, oldData} = e;
        const {dataField, rowIndex} = cellEditing.current;
        switch (dataField) {
            case "DisViolationActGroupID":
                const getNewDisViolationActGroupID = _.get(newData, "DisViolationActGroupID", "");
                if (!_.has(cboDisViolationAct, getNewDisViolationActGroupID)) {
                    loadCboDisViolationAct(getNewDisViolationActGroupID, data => {
                        setCboDisViolationAct(data)
                    });
                }
                fieldUpdating.current = {dataField: "DisViolationActID", value: ""};
                break;
            case "NumMonth":
                const getNewMonth = _.get(newData, "NumMonth", 0);
                if (!oldData?.ValidDate && oldData?.ValidEndDate) {
                    fieldUpdating.current = handleUpdateCell("ValidDate", oldData.ValidEndDate, getNewMonth);
                }
                if ((!oldData?.ValidEndDate && oldData?.ValidDate) || (oldData?.ValidEndDate && oldData?.ValidDate)) {
                    fieldUpdating.current = handleUpdateCell("ValidEndDate", oldData.ValidDate, getNewMonth);
                }

                break;
            case "ValidDate":
                const getNewValidDate = _.get(newData, "ValidDate", null);
                if (oldData?.ValidEndDate) {
                    fieldUpdating.current = handleUpdateCell("NumMonth", oldData.ValidEndDate, getNewValidDate);
                    if (_.isNaN(fieldUpdating.current.value)) fieldUpdating.current = {
                        ...fieldUpdating.current,
                        value: newData?.NumMonth ? newData?.NumMonth : _.get(dataGrid, `[${rowIndex}]NumMonth`, 0)
                    }
                }
                if (!oldData?.ValidEndDate && _.isNumber(oldData?.NumMonth)) {
                    fieldUpdating.current = handleUpdateCell("ValidEndDate", getNewValidDate, oldData?.NumMonth);
                }
                break;
            case "ValidEndDate":
                const getNewValidEndDate = _.get(newData, "ValidEndDate", null);
                if (oldData?.ValidDate) {
                    fieldUpdating.current = handleUpdateCell("NumMonth", getNewValidEndDate, oldData.ValidDate);
                    if (_.isNaN(fieldUpdating.current.value)) fieldUpdating.current = {
                        ...fieldUpdating.current,
                        value: newData?.NumMonth ? newData?.NumMonth : _.get(dataGrid, `[${rowIndex}]NumMonth`, 0)
                    }
                }
                break;
            default:
                break;
        }
    };

    const onAttachment = () => {
        if (attRef) {
            attRef.current.onAttachment();
        }
    };

    const onSave = () => {
        const dataSource = refDataGrid.instance.option("dataSource");
        let getVoucherID = {};
        let apiAction = "save";
        if (mode === "edit") {
            getVoucherID = {VoucherID: _.get(rowData, "VoucherID", "")}
            apiAction = "update"
        }

        const arrAttachments = _getAttachments();
        const params = {
            FormID: "W09F2271",
            Language: Config.language || "84",
            Data: JSON.stringify(_.map(dataSource, item => ({...item, IsCheck: !!item.IsCheck ? 1 : 0}))),
            arrAttachment: JSON.stringify(arrAttachments),
            ...dataForm,
            ...getVoucherID
        };
        const objErrors = _.reduce([{
            name: "VoucherName",
            isValid: !!dataForm.VoucherName
        },
            {
                name: "VoucherName",
                isValid: !!dataForm.ViolationDate
            },
            {
                name: "dataGrid",
                isValid: !!_.size(dataSource) || !_.every(dataSource, item => !!item.Deleted)
            }], (acc, currItem) => {
            if (!currItem.isValid) acc = {
                ...acc,
                [currItem.name]: currItem.name === "dataGrid" ? Config.lang("Vui_long_chon_nhan_vien") : Config.lang("Truong_nay_bat_buoc_nhap")
            }
            return acc;
        }, {})
        setErrors(objErrors);
        if (_.size(objErrors)) return false;
        (async () => {
            setSaving(true)
            const res = await Api.put("/w09f2271/" + apiAction, params);
            setSaving(false)
            const getStatus = _.get(res, "data.Status", 1);
            const getMessageErr = _.get(res, "message", "") || _.get(res, "data.Message", "");
            if (!getStatus) {
                Config.notify.show("success", Config.lang("Luu_thanh_cong"), 2000);
                await saveHistory(params, _.get(res, "data.TransID", ""));
                onClose(true);
            }
            if (!!getMessageErr || !!getStatus) Config.popup.show("ERROR", getMessageErr || Config.lang("Loi_khong_xac_dinh"));
        })()
    };

    const saveHistory = async (data, _TransID) => {
        let getTransID = _TransID;
        let getAction = 0;
        let dataSource = JSON.parse(_.get(data, "Data", []));
        if (mode === "edit") {
            getTransID = _.get(rowData, "VoucherID", "");
            getAction = 1;
        }

        if (getTransID) {
            const captions = {
                VoucherName: "ten_phieu",
                Notes: "ghi_chu",
                VoucherCreateDate: "ngay_tao_phieu",
                ViolationDate: "ngay_vi_pham",
            };
            // name detail grid
            let detailCaptions = {
                EmployeeName: "nhan_vien",
                ValidDate: "ngay_hieu_luc",
                ProDisRewardFormName: "hinh_thuc_ky_luat",
                ProDisRewardLevelName: "cap_ky_luat",
                ProContent: "noi_dung",
                ProNotice: "ghi_chu",
                ...(_.reduce(getRewardCaption, (acc, currItem, currIdx) => {
                    acc = {...acc, [currItem.Code]: "he_so_" + currIdx}
                    return acc;
                }, {}))
            };
            const _dataOld = oldDataForm.current;

            const options = {
                data: data,
                captions: captions,
                dataCompare: _dataOld,
                action: getAction,
                ModuleID: "D09",
                TransID: getTransID,
                TransactionID: "W09F2270",
                TransactionName: "Kỷ luật hàng loạt",
            };
            const history = new History(options);
            history.createDetailHistory("Nhan_vien", dataSource, oldDataGrid.current, detailCaptions, "EmployeeID", null, options); //Create detail..
            await history.save();
        }
    }

    const saveGrid = () => {
        refDataGrid.instance.saveEditData().done(() => {
            setTimeout(() => {
                if (editing.current === 0) {
                    onSave();
                } else {
                    editing.current = 0;
                }
            }, 200);
        });
    };

    const renderHeaderColumns = (e) => {
        return (
            <HeadClick selectedRange={selectedRange.current}
                       dataHeaderCell={e}
                       allowClick={true}
                       onUpdate={(data, col) => {
                           let newData = [];
                           switch (col.dataField) {
                               case "ValidDate":
                                   const getNewValidDate = _.get(data, "ValidDate", null);
                                   newData = _.map(dataGrid, item => {
                                       let fieldUpdate;
                                       const getValidEndDate = _.get(item, "ValidEndDate", null);
                                       const getNumMonth = _.get(item, "NumMonth", 0);
                                       if (getValidEndDate) {
                                           const dataUpdated = handleUpdateCell("NumMonth", getValidEndDate, getNewValidDate);
                                           fieldUpdate = {NumMonth: dataUpdated.value};
                                       } else {
                                           const dataUpdated = handleUpdateCell("ValidEndDate", getNewValidDate, getNumMonth);
                                           fieldUpdate = {ValidEndDate: dataUpdated.value}
                                       }
                                       return ({...item, ...fieldUpdate, Updated: 1})
                                   });
                                   break;
                               case "ValidEndDate":
                                   const getNewValidEndDate = _.get(data, "ValidEndDate", null);
                                   newData = _.map(dataGrid, item => {
                                       let fieldUpdate;
                                       const getValidDate = _.get(item, "ValidDate", null);
                                       if (getValidDate) {
                                           const dataUpdated = handleUpdateCell("NumMonth", getNewValidEndDate, getValidDate);
                                           fieldUpdate = {NumMonth: dataUpdated.value};
                                       }
                                       return ({...item, ...fieldUpdate, Updated: 1})
                                   });
                                   break;
                               case "NumMonth":
                                   const getNewNumMonth = _.get(data, "NumMonth", 0);
                                   newData = _.map(dataGrid, item => {
                                       let fieldUpdate;
                                       const getValidDate = _.get(item, "ValidDate", null);
                                       const getValidEndDate = _.get(item, "ValidEndDate", null);
                                       if (getValidDate && (!getValidEndDate || getValidEndDate)) {
                                           const dataUpdated = handleUpdateCell("ValidEndDate", getValidDate, getNewNumMonth);
                                           fieldUpdate = {ValidEndDate: dataUpdated.value}
                                       }
                                       if (!getValidDate && getValidEndDate) {
                                           const dataUpdated = handleUpdateCell("ValidDate", getValidEndDate, getNewNumMonth);
                                           fieldUpdate = {ValidDate: dataUpdated.value}
                                       }
                                       return ({...item, ...fieldUpdate, Updated: 1});
                                   });
                                   break;

                               default:
                                   break;

                           }
                           !_.isEmpty(newData) && setDataGrid(newData);
                       }}
                       conditionCopy={(eChild) => {
                           const {data} = eChild;
                           const dataSource = refDataGrid.instance.option("dataSource");
                           return (dataSource.find(i => !!i.IsCheck) && eChild.key !== "IsCheck") ? data.IsCheck : 1;
                       }}
            />
        )
    };

    const renderEmployee = (e) => {
        const {data} = e.row;
        const user = Config.getUser({EmployeeID: data.EmployeeID});
        return <div style={{display: "flex", alignItems: "center"}}>
            <Avatar
                style={{padding: 10}}
                clearAble={false}
                width={32}
                height={32}
                readOnly
                src={user?.UserPictureURL || ''}
            />
            <Label>{user?.EmployeeName || ""}</Label>
        </div>
    };

    const calculateSelectedRow = (options) => {
        if (options.name === 'RowsSummary') {
            if (options.summaryProcess === 'start') {
                options.totalValue = 0;
            } else if (options.summaryProcess === 'calculate') {
                if (!options.value?.Deleted) {
                    options.totalValue += 1;
                }
            }
        }
    };

    const cellRenderAction = (e) => {
        if (!e) return null;
        const {row} = e;
        return (
            <ButtonIcon
                circular={true}
                size={"tiny"}
                viewType={"text"}
                disabled={false}
                name={"delete"}
                onClick={() => {
                    Config.popup.show("YES_NO", Config.lang("Ban_co_chac_chan_muon_xoa_du_lieu_nay"), () => {
                        if (mode === "add") setDataGrid(_.filter(dataGrid, item => item.EmployeeID !== row.data.EmployeeID));
                        if (mode === "edit") {
                            e.component.cellValue(e.rowIndex, "Deleted", 1);
                            e.component.saveEditData();
                        }
                    })
                }}
            />
        );
    };

    const rewardCaptionData = _.filter(getRewardCaption, ["Disabled", 0]);

    return <>
        {isShowModalW09F6000 && <W09F6000
            open={isShowModalW09F6000}
            FormID={"W09F2270"}
            onChosen={onSelectedEmployees}
            onClose={() => setShowModalW09F6000(false)}/>
        }
        <Modal fullScreen open={open}
               onClose={() => onClose(false)}
               title={Config.lang("Lap_ky_luat_hang_loat").toUpperCase()}>
            <Modal.Content>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={6}>
                            <TextInput
                                multiline
                                error={errors["VoucherName"]}
                                onBlur={(e) => validation("VoucherName", e.target.value || "")}
                                required
                                readOnly={mode === "view" || (mode === "edit" && _.toNumber(rowData?.AppStatusID) !== 0)}
                                value={dataForm.VoucherName}
                                disabled={isLoadingForm || isSaving}
                                onChange={(e) => onChangeForm("VoucherName", e)}
                                label={Config.lang("Ten_phieu")}
                                placeholder={Config.lang("Nhap")}
                            />
                        </Col>
                        <Col xs={12} sm={6}>
                            <TextInput
                                multiline
                                readOnly={mode === "view" || (mode === "edit" && _.toNumber(rowData?.AppStatusID) !== 0)}
                                value={dataForm.Notes}
                                disabled={isLoadingForm || isSaving}
                                onChange={(e) => onChangeForm("Notes", e)}
                                label={Config.lang("Ghi_chu")}
                                placeholder={Config.lang("Nhap")}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup style={{minHeight: 90}}>
                    <Row>
                        <Col xs={12} sm={6}>
                            <DatePicker
                                clearAble
                                label={Config.lang("Ngay_lap_phieu")}
                                actionIconAt="start"
                                disabled={isLoadingForm || isSaving}
                                value={dataForm.VoucherCreateDate}
                                displayFormat={"DD/MM/YYYY"}
                                placeholder={"DD/MM/YYYY"}
                                onChange={e => onChangeForm('VoucherCreateDate', e)}
                            />
                        </Col>
                        <Col xs={12} sm={6}>
                            <DatePicker
                                required
                                clearAble
                                onBlur={(e) => validation("ViolationDate", e.target.value || "")}
                                label={Config.lang("Ngay_vi_pham")}
                                actionIconAt="start"
                                error={errors['ViolationDate']}
                                disabled={isLoadingForm || isSaving}
                                value={dataForm.ViolationDate}
                                displayFormat={"DD/MM/YYYY"}
                                placeholder={"DD/MM/YYYY"}
                                onChange={e => onChangeForm('ViolationDate', e)}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12}>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: 8
                            }}>
                                <Label required>{Config.lang("Danh_sach_ky_luat")}</Label>
                                {!!errors["dataGrid"] ?
                                    <HelperText status={"danger"}>{errors["dataGrid"]}</HelperText> : ""}
                                <Button label={Config.lang('Chon_nhan_vien')}
                                        startIcon={"AddPerson"}
                                        color={"primary"}
                                        viewType={"outlined"}
                                        style={{textTransform: 'uppercase'}}
                                        size={"medium"}
                                        onClick={() => setShowModalW09F6000(true)}
                                        disabled={isSaving || mode === "view" || (mode === "edit" && _.toNumber(rowData?.AppStatusID) !== 0)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <GridContainer
                                reference={ref => refDataGrid = ref}
                                dataSource={dataGrid}
                                keyExpr="EmployeeID"
                                showRowLines={true}
                                showBorders={false}
                                columnAutoWidth={true}
                                showColumnLines={false}
                                hoverStateEnabled={true}
                                rowAlternationEnabled={false}
                                pagerFullScreen={false}
                                noDataText={Config.lang("No_data")}
                                style={{border: "none", overflowX: "scroll"}}
                                loading={isLoadingGrid || isSaving}
                                editing={{
                                    mode: 'cell',
                                    refreshMode: 'full',
                                    allowUpdating: mode !== "view" || (mode === "edit" && _.toNumber(rowData?.AppStatusID) !== 0),
                                }}
                                height={Config.getHeightGrid() - 280}
                                filterValue={filterGridValue}
                                filterPanel={{visible: true}}
                                filterRow={{visible: true, showOperationChooser: false}}
                                filterBuilderPopup={{
                                    onInitialized: (e) => {
                                        e.component.dispose();
                                        e.element.remove();
                                    }
                                }}
                                onRowValidating={e => {
                                    editing.current = +!e.isValid;
                                }}
                                sorting={{mode: "none"}}
                                onEditingStart={onEditingStart}
                                onCellPrepared={e => e.separator = ","}
                                onRowPrepared={onRowPrepared}
                                onFilterGrid={onFilterGrid}
                                onCellSelectionChanged={(e) => {
                                    if (e.selectedRange && e.selectedRange.rowType !== "header") {
                                        selectedRange.current = e.selectedRange;
                                    }
                                }}
                                onEditorPreparing={onEditorPreparing}
                                onRowUpdating={onRowUpdating}
                                onRowUpdated={onRowUpdated}
                            >
                                <Column
                                    caption={Config.lang("Hanh_dong")}
                                    alignment={"center"}
                                    visible={Config.isMobile && mode !== "view"}
                                    allowEditing={false}
                                    cellRender={cellRenderAction}
                                />
                                <Column
                                    caption={Config.lang("Chon")}
                                    dataField={'IsCheck'}
                                    trueText={Config.lang("Có")}
                                    falseText={Config.lang("Không")}
                                    dataType={"boolean"}
                                    allowEditing={true}
                                    fixed={true}
                                    allowFiltering={true}
                                    headerCellRender={renderHeaderColumns}
                                    allowCopying={true}
                                    allowPasting={true}
                                    minWidth={80}
                                />
                                <Column caption={Config.lang("Ma_nhan_vien")}
                                        dataField={'EmployeeID'}
                                        allowEditing={false}
                                        fixed={true}
                                        minWidth={130}
                                />
                                <Column caption={Config.lang("Ten_nhan_vien")}
                                        dataField={'EmployeeName'}
                                        allowEditing={false}
                                        cellRender={renderEmployee}
                                        fixed={true}
                                        minWidth={180}
                                />
                                <Column headerCellRender={renderHeaderColumns}
                                        allowCopying={true}
                                        allowPasting={true}
                                        allowEditing={true}
                                        alignment={"right"}
                                        caption={Config.lang("Ngay_hieu_luc")}
                                        dataField={"ValidDate"}
                                        dataType={"date"}
                                        format={"dd/MM/yyyy"}
                                        width={140}
                                        editorOptions={{
                                            showClearButton: true,
                                        }}
                                >
                                    <CustomRule
                                        validationCallback={e => !!e.data.ValidDate}
                                        message="Giá trị bắt buộc nhập"/>
                                </Column>
                                <Column headerCellRender={renderHeaderColumns}
                                        allowCopying={true}
                                        allowPasting={true}
                                        allowEditing={true}
                                        alignment={"right"}
                                        caption={Config.lang("Ngay_hieu_luc_den")}
                                        dataField={"ValidEndDate"}
                                        dataType={"date"}
                                        format={"dd/MM/yyyy"}
                                        editorOptions={{
                                            showClearButton: true,
                                        }}
                                        width={140}
                                />
                                <Column headerCellRender={renderHeaderColumns}
                                        allowCopying={true}
                                        allowPasting={true}
                                        alignment={"center"}
                                        editorOptions={{
                                            showClearButton: true,
                                        }}
                                        width={120}
                                        caption={Config.lang("So_thang")}
                                        dataField={"NumMonth"}/>
                                <Column alignment={"center"}
                                        minWidth={140}
                                        lookup={{
                                            dataSource: getCboDisViolationActGroup,
                                            valueExpr: "DisViolationActGroupID",
                                            displayExpr: "DVAGroupName",
                                        }}
                                        editorOptions={{
                                            showClearButton: true,
                                        }}
                                    // allowCopying={true}
                                    // allowPasting={true}
                                        caption={Config.lang("Nhom_hanh_vi_vi_pham_ky_luat")}
                                        dataField={"DisViolationActGroupID"}
                                />
                                <Column alignment={"center"}
                                        caption={Config.lang("Hanh_vi_vi_pham_ky_luat")}
                                        allowCopying={true}
                                        allowPasting={true}
                                        lookup={{
                                            dataSource: _(cboDisViolationAct)
                                                .keys()
                                                .reduce((acc, currKey) => {
                                                    acc = [...acc, ...cboDisViolationAct[currKey]];
                                                    return acc;
                                                }, []),
                                            valueExpr: "DisViolationActID",
                                            displayExpr: "DVAName",
                                        }}
                                        editorOptions={{
                                            showClearButton: true,
                                        }}
                                        minWidth={140}
                                        dataField={"DisViolationActID"}
                                />
                                <Column headerCellRender={renderHeaderColumns}
                                        allowCopying={true}
                                        allowPasting={true}
                                        allowEditing={true}
                                        caption={Config.lang("Hinh_thuc_ky_luat")}
                                        width={140}
                                        lookup={{
                                            dataSource: getCboDisRewardForm,
                                            valueExpr: "ProDisRewardFormID",
                                            displayExpr: "ProDisRewardFormName",
                                        }}
                                        dataField={"ProDisRewardFormID"}/>
                                <Column headerCellRender={renderHeaderColumns}
                                        allowCopying={true}
                                        allowPasting={true}
                                        allowEditing={true}
                                        editorOptions={{
                                            showClearButton: true,
                                        }}
                                        lookup={{
                                            dataSource: getCboProDisRewardLevel,
                                            valueExpr: "ProDisRewardLevelID",
                                            displayExpr: "ProDisRewardLevelName",
                                        }}
                                        width={140}
                                        caption={Config.lang("Cap_ky_luat")}
                                        dataField={"ProDisRewardLevelID"}/>
                                <Column headerCellRender={renderHeaderColumns}
                                        allowCopying={true}
                                        editorOptions={{
                                            showClearButton: true,
                                        }}
                                        width={220}
                                        allowPasting={true}
                                        allowEditing={true}
                                        caption={Config.lang("Noi_dung")}
                                        dataField={"ProContent"}/>
                                {_.map(rewardCaptionData, (item, k) => <Column key={k}
                                                                               dataType={"number"}
                                                                               format={{
                                                                                   type: 'decimal',
                                                                                   min: 0
                                                                               }}
                                                                               width={120}
                                                                               calculateDisplayValue={cellValue => Number(cellValue[item.Code] || 0).toLocaleString()}
                                                                               editorOptions={{
                                                                                   min: 0,
                                                                                   showClearButton: true,
                                                                                   format: {
                                                                                       precision: Number(item?.Decimals) || 4,
                                                                                   },
                                                                               }}
                                                                               headerCellRender={renderHeaderColumns}
                                                                               allowCopying={true}
                                                                               allowPasting={true}
                                                                               allowEditing={true}
                                                                               alignment={"center"}
                                                                               caption={item.Short || ""}
                                                                               dataField={item.Code || ""}/>)}
                                <Column
                                    visible={false}
                                    dataField={'Deleted'}
                                    allowEditing={false}
                                />
                                <Column
                                    visible={false}
                                    dataField={'Updated'}
                                    allowEditing={false}
                                />
                                <Column
                                    visible={false}
                                    dataField={'AddEmp'}
                                    allowEditing={false}
                                />
                                <Column
                                    alignment={"center"}
                                    caption={" "}
                                    allowEditing={false}
                                    fixed={true}
                                    fixedPosition={"right"}
                                    width={120}
                                    // visible={!Config.isMobile && mode !== "view"}
                                    cellRender={cellRenderAction}
                                />
                                <Summary calculateCustomSummary={calculateSelectedRow}>
                                    <TotalItem
                                        column="IsCheck"
                                        displayFormat={`${Config.lang("DHR_Tong")}:`}
                                    />

                                    <TotalItem
                                        name={"RowsSummary"}
                                        summaryType="custom"
                                        displayFormat={"{0}"}
                                        showInColumn="EmployeeID"
                                    />
                                </Summary>
                            </GridContainer>
                        </Col>
                    </Row>
                </FormGroup>
                <Accordion expand={false}>
                    <AccordionSummary>
                        <Label>{Config.lang("Dinh_kem")}</Label>
                    </AccordionSummary>
                    <AccordionDetails style={{marginTop: 8}}>
                        <Attachments
                            onUploading={setUploading}
                            ref={ref => attRef.current = ref}
                            disabled={isSaving || isLoadingAttachment || (mode === "edit" && _.toNumber(rowData?.AppStatusID) !== 0)}
                            uploading={uploading}
                            error={false}
                            showButton={true}
                            files={dataOldAttachments}
                            isLocal={true}
                            onChanged={onChangeAttachments}
                        />
                    </AccordionDetails>
                </Accordion>
            </Modal.Content>
            <Modal.Actions style={{justifyContent: "space-between"}}>
                <div>
                    <ButtonIcon
                        disabled={isSaving || mode === "view" || (mode === "edit" && _.toNumber(rowData?.AppStatusID) !== 0)}
                        name={"attachment"}
                        circular
                        viewBox
                        viewType={"text"}
                        onClick={onAttachment}
                    />
                </div>
                <div>
                    <Button
                        color={"info"}
                        viewType={'filled'}
                        text={Config.lang('Luu')}
                        startIcon={'Save'}
                        disabled={isLoadingForm || mode === "view" || _.size(errors) || (mode === "edit" && _.toNumber(rowData?.AppStatusID) !== 0)}
                        onClick={saveGrid}
                    />
                </div>
            </Modal.Actions>
        </Modal>
    </>
}

export default compose(connect(
    (state) => ({
        getCboDisRewardForm: state.W09F2070.getCboDisRewardForm,
        getCboProDisRewardLevel: state.W09F2070.getCboProDisRewardLevel,
        getCboDisViolationActGroup: state.W09F2070.getCboDisViolationActGroup,
        getCboDisViolationAct: state.W09F2070.getCboDisViolationAct,
        getRewardCaption: state.W09F2070.getRewardCaption,
    }),
    dispatch => ({
            w09f2070Actions: bindActionCreators(W09F2070Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch)
        }
    )), withStyles(styles))(W09F2271);
